import axios from "axios";
//import authHeader from "./auth-header";
import baseUrl from "@routes/url";

const get = (group) => {
  let token = localStorage.getItem("auth-token");
  if (token === null) {
    localStorage.setItem("auth-token", "");
    token = "";
  }
  return axios
    .get(`${baseUrl}/traductions/get/${group}`, {
      headers: { "x-auth-token": token },
    })
    .then((res) => {
      console.log("Result for traductions get function => ", res);
      if (res.data.success) {
        let all_traductions = res.data.traductions;
        let traductions_structured = [];
        let all_namespaces = [];
        if (all_traductions.length > 0) {
          for (let index_t in all_traductions) {
            let traduction_group = all_traductions[index_t];
            if (
              traduction_group.sections &&
              traduction_group.sections.length > 0
            ) {
              let sections = traduction_group.sections;
              for (let i in sections) {
                traductions_structured[sections[i]["title"]] =
                  sections[i]["value"];
                all_namespaces.push(sections[i]["title"]);
              }
            }
          }
        }
        let response = {
          namespaces: all_namespaces,
          resources: traductions_structured,
          success: res.data.success,
          message: res.data.message,
        };
        localStorage.setItem("traductions", JSON.stringify(response));
        return response;
      } else {
        return res.data;
      }
      //return res;
    });
};

export default {
  get,
};

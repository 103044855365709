//import http from "../http-common";
import BaseService from "./base.service";
import http from "../http-common";
import { getResponseData, customizeError, checkEnterpriseId } from "../helpers/_functions";

class WorkedHoursTypesService extends BaseService {
  constructor() {
    super("worked-hours-types");
  }

  injectNethrisTypes(enterpriseId) {
    return http
        .patch(`${this.path}/injectnethristypes/${enterpriseId}`)
        .then(res => getResponseData(res))
        .catch((err) => Promise.reject(customizeError(err)));
  }

  getAllByEnterprise() {
    const enterpriseId = checkEnterpriseId();
    return http
        .get(`${this.path}/listAll/${enterpriseId}`)
        .then(res => getResponseData(res))
        .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new WorkedHoursTypesService();

import { makeActionHandler } from "./_reducerUtils";

export const notificationData = {
  initialState: {
    standardNotification: {},
    unreadNotifications: {
      all: 0,
      employee: 0,
      enterprise: 0
    },
    employeeNotification: {
      list: [],
      toSkipNext: 0,
      endReached: false
    },
    enterpriseNotification: {
      list: [],
      toSkipNext: 0,
      endReached: false
    }
  },
  handlers: {
    "notification/STANDARD_NOTIFICATION": makeActionHandler("standardNotification"),
    "notification/SET_UNREAD_NOTIFICATIONS": makeActionHandler("unreadNotifications"),
    "notification/SET_EMPLOYEE_NOTIFICATION": makeActionHandler("employeeNotification"),
    "notification/SET_ENTERPRISE_NOTIFICATION": makeActionHandler("enterpriseNotification"),
    
    "notification/RESET_ALL": () => notificationData.initialState,
    "RESET_ALL": () => notificationData.initialState
  }
};

export default notificationData;

import BaseService from "./base.service";
import http from "../http-common";
class PermissionService extends BaseService {
  constructor() {
    super("permissions");
  }
  getById(id) {
    return http.get(`${this.path}/listById/${id}`);
  }
}

export default new PermissionService();

import BaseService from "../base.service";
import http from "../../http-common";
import { getResponseData, customizeError, checkEnterpriseId } from "../../helpers/_functions";

class EmployeurDService extends BaseService {
    constructor() {
        super("employeurd");
    }

    sendPayFile(params) {
        return http.post(`${this.path}/payfile`, params)
                .then(res => getResponseData(res))
                .catch((err) => Promise.reject(customizeError(err)));
    }

    getEmployeeList(enterpriseId) {
        return http.get(`${this.path}/employee-list/${enterpriseId}`)
            .then(res => getResponseData(res))
            .catch((err) => Promise.reject(customizeError(err)));
    }
    getLists(enterpriseId,id,params={}) {
        return http.get(`${this.path}/getLists/${id}/${enterpriseId}`,{params})
            .then(res => getResponseData(res))
            .catch((err) => Promise.reject(customizeError(err)));
    }

    // getEmployeesSynchronised(enterpriseId,userId) {
    //     return http.get(`${this.path}/employees-list-synchronised/${enterpriseId}/${userId}`).then(res => getResponseData(res))
    //     .catch((err) => Promise.reject(customizeError(err)));;
    //   }
    addEmployee(params, userId, enterpriseId){
        return http.post(`${this.path}/add/${userId}/${enterpriseId}`, params)
                .then(res => getResponseData(res))
                .catch((err) => Promise.reject(customizeError(err)));
    }
    updateEmploye(params, userId, enterpriseId){
        return http.post(`${this.path}/update/${userId}/${enterpriseId}`, params)
                .then(res => getResponseData(res))
                .catch((err) => Promise.reject(customizeError(err)));
    }
    getWorkingHours(id, filters = {}) {
        const enterpriseId = checkEnterpriseId();
        const params = {
          userId: localStorage.getItem("userId"),
          ...filters
        }
        return http.get(`${this.path}/working-hours/${id}/${enterpriseId}`, { params });
    }
}

export default new EmployeurDService();
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setNotificationError,
  setNotificationInfo,
  setNotificationSuccess,
  setNotificationWarning,
  translateNotification
} from "@helpers/_functions";

const useNotification = () => {
  const { t: notificationTranslator } = useTranslation("message");

  const dispatch = useDispatch();

  const showError = useCallback((message, option) => {
    setNotificationError(
      dispatch,
      translateNotification(notificationTranslator, message, option)
    );
  }, []);

  const showInfo = useCallback((message, option) => {
    setNotificationInfo(
      dispatch,
      translateNotification(notificationTranslator, message, option)
    );
  }, []);

  const showSuccess = useCallback((message, option) => {
    setNotificationSuccess(
      dispatch,
      translateNotification(notificationTranslator, message, option)
    );
  }, []);
  const showWarning = useCallback((message, option) => {
    setNotificationWarning(
      dispatch,
      translateNotification(notificationTranslator, message, option)
    );
  }, []);

  return {
    dispatch,
    showInfo,
    showError,
    showSuccess,
    showWarning,
    notificationTranslator
  };
};

const withNotification = (WrappedComponent) => (props) => {
  const { showSuccess, showWarning, showInfo, showError } = useNotification();
  return (
    <WrappedComponent
      {...props}
      showError={showError}
      showInfo={showInfo}
      showSuccess={showSuccess}
      showWarning={showWarning}
    />
  );
};

export { useNotification, withNotification };
export default withNotification;

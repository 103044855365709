import { customizeError, getResponseData } from "@helpers/_functions";

import http from "../http-common";
import BaseService from "./base.service";

class WorkingHourService extends BaseService {
  constructor() {
    super("working-hours");
  }
  getByEmployer(id) {
    return http.get(`${this.path}/getByEmployer/${id}`);
  }
  downloadGabarit() {
    return http.get(`${this.path}/gabarit`, {
      responseType: "blob"
    });
  }
  importSchedule(data) {
    return http
      .post(`${this.path}/import`, data)
      .then((response) => response.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }
  injectPlannedHours(data){
    return http
      .post(`${this.path}/inject-planned-hours`, data)
      .then((response) => response.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }
  createBlocksHours(blocksHours) {
    return http.post(`${this.path}/create_blocks`, blocksHours);
  }
  upsertManyHours(hours) {
    return http.post(`${this.path}/upsert_hours`, hours);
  }
  getImagesByEmployer(id) {
    return http.get(`${this.path}/getImagesByEmployer/${id}`);
  }
  deleteByEmployer(id) {
    return http.delete(`${this.path}/deleteByEmployer/${id}`);
  }
  verifyDuplicatedWorkingHours(data) {
    return http.post(`${this.path}/verify-duplicated-working-hours`, data);
  }
  getLastShiftByEmployer(id) {
    return http.get(`${this.path}/getLastShiftByEmployer/${id}`);
  }
  getLastEndDateByEmploye(employeId, timezone, data) {
    const encodedTimezone = encodeURIComponent(timezone);
    const url = `${this.path}/getLastEndDateByEmploye/${employeId}/${encodedTimezone}`;
    const encodedUrl = encodeURI(url);
    return http.post(encodedUrl, data);
  }
  getDetailedReport(userId, enterpriseId, filter) {
    return http.post(
      `${this.path}/getDetailedReport/${userId}/${enterpriseId}`,
      filter
    );
  }
  getDuplicatesByPeriod(params) {
    return http
      .get(`${this.path}/getDuplicatesByPeriod/${params?.userId}`, {
        params
      })
      .then((response) => getResponseData(response));
  }

  insertMany(data) {
    return http.post(`${this.path}/create-many`, data);
  }
}

export default new WorkingHourService();

import BaseDocumentationService from "./baseDocumentation.service";
import {
  customizeError,
  getResponseData
} from "@helpers/_functions";
import http from "../../http-common";

class DocumentationFileService extends BaseDocumentationService {
  constructor() {
    super("documentation-file");
  }

  upload(formData) {
    return http
      .post(`${this.path}/upload`, formData)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getFileByDocument(documentId) {
    return http
      .get(`${this.path}/documentation/${documentId}`)
      .then((response) => response.data.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new DocumentationFileService();

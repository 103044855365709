import React from "react";

/**
 *
 * @param {Object} props
 * @param {string} props.bgColor - Class correspondant au Background color
 * @param {string} props.textColor - Class correspondant au Text color
 * @param {string} props.text
 * @param {keyof typeof MAX_WIDTH_CLASS} props.maxWidth max width class
 * @returns
 */
function Badge({ bgColor, textColor, text, maxWidth = "default" }) {
  return (
    <p
      className={`arh-mb-0 arh-truncate arh-rounded-full arh-px-4 arh-py-0.5 arh-text-center arh-font-poppins arh-text-[11px] arh-font-medium ${bgColor} ${textColor} ${MAX_WIDTH_CLASS[maxWidth]}`}
    >
      {text}
    </p>
  );
}

const MAX_WIDTH_CLASS = {
  default: "arh-max-w-[150px]",
  "half-page": "arh-max-w-[35vw]",
};

export default Badge;

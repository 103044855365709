import React from "react";

/**
 * @typedef {Object} Props
 * @property {string=} modifier Class pour modifier l'aspect du lien
 * @property {string=} label
 * @property {boolean=} leftIcon
 * @property {boolean=} rightIcon
 * @property {boolean=} wait
 * @property {string=} href
 * @property {boolean=} disabled
 * @property {string} target
 * @property {string} rel
 */

/**
 *
 * @param {Props} props
 * @returns
 */
const Link = (props) => {
  const {
    modifier,
    label,
    leftIcon,
    rightIcon,
    wait,
    href,
    disabled,
    target,
    rel,
  } = props;

  /*
   * modifier { width, background-color, border-color, font-weight, font-size, text-color, hover effect ...}
   */
  return (
    <a
      className={`arh-flex arh-w-full arh-items-center arh-justify-center arh-rounded-[3px] arh-border arh-border-solid arh-py-3 arh-font-poppins arh-transition-all arh-duration-200 arh-ease-in ${
        wait && "arh-cursor-wait"
      } ${
        disabled &&
        "arh-pointer-events-none arh-cursor-not-allowed arh-opacity-60"
      } ${modifier}`}
      href={href}
      target={target}
      rel={rel}
    >
      {leftIcon && <span className="arh-mr-2">{leftIcon}</span>}
      {label}
      {rightIcon && <span className="arh-ml-2">{rightIcon}</span>}
    </a>
  );
};

export default Link;

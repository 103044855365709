import BaseService from "./base.service";
import http from "../http-common";
class EmplacementService extends BaseService {
  constructor() {
    super("emplacements");
  }
  getEmplacementFiltered(id, name) {
    return http.get(`${this.path}/filter/${id}/${name}`);
  }
}

export default new EmplacementService();


import React, { useMemo } from "react";
// import { useTranslation } from "react-i18next";
// import { dropGroup } from "@routes/IconeSvg";
import CheckboxSimple from "../CheckboxSimple";
import CheckboxGroupe from "./CheckboxGroupe";

function ListboxOption(props) {
  const { isSelected, onClick, color, groupe }= props;

  const isChecked = useMemo(() => {
    return isSelected(groupe);
  }, [isSelected, groupe]);

  const isDraggable = useMemo(() => {
    return groupe.subgroupes
      ? groupe.subgroupes.length > 0
      : groupe.subgroupes > 0;
  }, [groupe]);

  return (  
    <div className="arh-relative arh-cursor-pointer arh-select-none arh-list-none arh-font-poppins arh-font-normal arh-text-xs arh-text-darkColor arh-truncate">
      <CheckboxGroupe
        title={groupe.title}
        onClick={() => onClick(groupe)}
        draggable={isDraggable}
        checked={isChecked}
        color={props.color}
      />  

      {isDraggable && isChecked && (
        <ul className="arh-ml-[1.2rem] arh-mt-1 arh-max-h-60 arh-w-full arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-text-xs arh-z-20">
          {groupe.subgroupes.map((sousgroupe) => (
            <CheckboxSubGroup 
              key={sousgroupe?._id}
              groupe={sousgroupe}
              onClick={(_subsubgroup) => _subsubgroup ? onClick(_subsubgroup) : onClick(sousgroupe)}
              isSelected={isSelected}
              color={color}
            />
          ))}
        </ul>
      )}
    </div>
  );
}


function CheckboxSubGroup({ groupe, onClick, color, isSelected }) {
  
  return (
    <li
        key={groupe._id}
        className="arh-relative arh-cursor-pointer arh-select-none arh-list-none arh-font-poppins arh-font-normal arh-text-xs arh-text-darkColor arh-truncate"
    >
      <CheckboxSimple
        label={groupe.title}
        onClick={() => onClick(groupe)}
        checked={isSelected(groupe)}
        variant={color}
      />
      { Array.isArray(groupe.subgroupes) && groupe.subgroupes.length > 0 && (
        <ul className="arh-ml-[1.2rem] arh-mt-1 arh-max-h-60 arh-w-full arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-text-xs arh-z-20">
          {groupe.subgroupes.map((sousgroupe) => (
            <li
              key={sousgroupe._id}
              onClick={() => onClick(sousgroupe)}
              className="arh-relative arh-cursor-pointer arh-select-none arh-list-none arh-font-poppins arh-font-normal arh-text-xs arh-text-darkColor arh-truncate"
            >
              <CheckboxSubGroup
                groupe={sousgroupe}
                onClick={(_subsubgroup) => _subsubgroup ? onClick(_subsubgroup) : onClick(sousgroupe)}
                isSelected={isSelected}
                color={color}
              />
            </li>
          ))}
        </ul>
      )}
    </li>
  )
}


export default ListboxOption;

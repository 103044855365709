import BaseService from "./base.service";
import http from "../http-common";
class EmployerAssignmentService extends BaseService {
  constructor() {
    super("employer-assignment");
  }
 
  createAssignation(item) {
    return http.post(`${this.path}/updateOne`, item);
  }
  
}

export default new EmployerAssignmentService();
import BaseService from "./base.service";
import http from "../http-common";
class UploadService extends BaseService {
  constructor() {
    super("upload");
  }
  updateUserPhoto(item, id) {
    return http.put(`${this.path}/update/${id}`, item, {});
  }
  uploadUserPhoto(item) {
    return http.post(`${this.path}/user-profile`, item, {});
  }
  createUserPhoto(item) {
    return http.post(`${this.path}/create`, item, {});
  }
}

export default new UploadService();

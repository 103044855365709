import { makeActionHandler } from "./_reducerUtils";

const certificationReducer = {
  initialState: {
    certification: {
      list: [],
      filter:{},
      isLoaded: false,
      isQueryProcessing: false
    },
  },
  handlers: {
    "certification/SET_LIST": makeActionHandler("certification"),
    "RESET_ALL": () => certificationReducer.initialState
  }
};

export default certificationReducer;

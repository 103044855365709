import BaseService from "./base.service";
import http from "../http-common";

class DayNotesService extends BaseService {
  constructor() {
    super("day-notes");
  }

  createDayNotes(notes) {
    return http.post(`${this.path}/create`, notes);
  }
}

export default new DayNotesService();
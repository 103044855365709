import React from "react";
import "@css/components/input.css";
import "@css/pages/dashboard/global.css";
import Inter from "@image/icones/langue/inter.svg";
import Shape from "@image/icones/langue/shape.svg";
import { reactMemo } from "@helpers/retyped-functions";

function InputSelectLanguage(props) {
  const {
    onClick=()=>{},
    type,
    onChange,
    value,
    refs,
    name,
    id,
    placeholder,
    height,
    padding,
    marginBottom,
    marginTop,
    dataId,
    testId,
    className="",
    classNameParent="",
    selectedLanguage={}
  } = props
    return (
      <div
        className={`arh-relative arh-flex arh-items-center arh-px-2 arh-py-3 arh-w-[87px] arh-bg-greyColor arh-rounded-[59px] arh-cursor-pointer mobile-768:arh-w-16 ${classNameParent}`}
        onClick={onClick}
      >
        <img
          alt="Icone_Language"
          className="arh-w-4 arh-h-4 arh-mr-[0.438rem] arh-object-cover"
          src={selectedLanguage?.iconeCode||Inter}
        />
        <input
          type={type}
          onChange={onChange}
          className={`arh-border-none arh-font-poppins arh-w-1/3 arh-bg-transparent arh-text-black arh-text-[11px] placeholder:arh-text-[11px] placeholder:arh-text-darkColor arh-cursor-pointer mobile-768:arh-hidden ${className}`}
          value={value || ""}
          ref={refs}
          name={name}
          id={id}
          placeholder={placeholder}
          readOnly
          style={{
            height: height,
            padding: padding,
            marginBottom: marginBottom,
            marginTop: marginTop
          }}
          data-id={dataId}
          data-testid={testId}
        />
        <div
          className={`arh-absolute arh-top-1/2 arh-pointer-events-none -arh-translate-y-1/2 arh-right-3 arh-flex arh-items-center arh-h-full arh-rotate-0 `}
        >
          <img alt="Shape_Icon" className="arh-object-cover" src={Shape} />
        </div>
      </div>
    );
}
export default reactMemo(InputSelectLanguage)

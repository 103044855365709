import BaseService from "../base.service";
import http from "../../http-common";
import {
  customizeError,
  getResponseData
} from "@helpers/_functions";
class FormationService extends BaseService {
  constructor() {
    super("formations");
  }
  getByEmployer(employerId, params = {}){
    return http
      .get(`${this.path}/get-by-employer/${employerId}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
}

export const Formation = new FormationService();
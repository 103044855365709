import React from "react";
import Loader from "react-loader-spinner";

export default function Loading(props) {
  return (
    <div
      className={
        "arh-flex arh-justify-center arh-items-center " +
        props?.class
      }
    >
      <Loader
        type="ThreeDots"
        color={props?.color}
        height={props?.height}
        width={props?.width}
      />
    </div>
  );
}

import { getResponseQuery } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

class EvaluationEvaluator extends BaseService {
  constructor() {
    super("evaluation-evaluator");
  }

  getEligibleEvaluators(adminUserId, selectedUserId = "") {
    let params = {};
    if (selectedUserId) {
      params = {
        selectedUserId,
      };
    }

    return getResponseQuery(
      http.get(`${this.path}/eligible-evaluator/${adminUserId}`, {
        params,
      }),
    );
  }

  getByEvaluationRequest(evaluationRequestId) {
    return getResponseQuery(
      http.get(`${this.path}/get-by-evaluation-request/${evaluationRequestId}`),
    );
  }

  deleteMany(reqbody) {
    return getResponseQuery(http.post(`${this.path}/delete-many`, reqbody));
  }
}

export default new EvaluationEvaluator();


import BaseService from "./base.service";
import http from "../http-common";

class BullesService extends BaseService {
    constructor() {
        super("bulles")
    }
    findAll() {
        return new Promise((resolve, reject) => {
            http
            .get(`${this.path}/`)
            .then(res => resolve(res?.data?.data))
            .catch(err => reject(err));
        });
    }
}

export default new BullesService();
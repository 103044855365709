import BaseService from "../base.service";
import { getResponseQuery } from "../../helpers/_functions";

class EvaluationCriteria extends BaseService {
  constructor() {
    super("evaluation-criteria");
  }

  createEvaluationCriteria = (data) => getResponseQuery(super.insert(data));
  updateEvaluationCriteria = (evaluationCriteria) => getResponseQuery(super.updateById(evaluationCriteria));
  updateOrderEvaluationCriteria = (arrayEvalutionsCriteria) => getResponseQuery(super.updateOrder(arrayEvalutionsCriteria));
  deleteEvaluationCriteria = (evaluationCriteriaId) => getResponseQuery(super.deleteById(evaluationCriteriaId));
}

export default new EvaluationCriteria();
import http from "../../http-common";
import BaseService from "../base.service";
import {
  customizeError,
  getResponseData,
} from "@helpers/_functions";

class TrackingFrontErrorService extends BaseService {
    constructor() {
        super("management/front")
    }
    
    saveFrontError(logInfo) {
        return http
            .put(`${this.path}/tracking-error`, logInfo)
            .then((response) => getResponseData(response))
            .catch((err) => {
                return Promise.reject(customizeError(err));
            });
    }
}

export default new TrackingFrontErrorService();


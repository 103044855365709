import BaseService from "./base.service";
import http from "../http-common";

class WorkingHourService extends BaseService {
  constructor() {
    super("timeclocks");
  }
  deleteByEnterprise(id) {
    return http.delete(`${this.path}/deleteByEnterprise/${id}`);
  }
  getByEmployer(employerId) {
    return http.get(`${this.path}/getByEmployer/${employerId}`);
  }
}

export default new WorkingHourService();

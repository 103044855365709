import BaseService from "../base.service";
import http from "../../http-common";
import { getResponseQuery } from "../../helpers/_functions";

class EvaluationNote extends BaseService {
    constructor() {
        super("evaluation-note");
    }

    createEvaluationNote = (data) => getResponseQuery(super.insert(data));

    updateEvaluationNote = (evaluationNote) =>
        getResponseQuery(super.updateById(evaluationNote));

    deleteEvaluationNote = (evaluationNoteId) =>
        getResponseQuery(super.deleteById(evaluationNoteId));

    getEvaluationNote = (enterpriseId, employerId) =>
        getResponseQuery(http.get(`${this.path}/get/${enterpriseId}/${employerId}`));

    getNoteByEmployer = (employerId, params = {}) =>
        getResponseQuery(http.get(`${this.path}/getByEmployer/${employerId}`, { params }));
    
}

export default new EvaluationNote();
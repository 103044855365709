import React, { useState, useMemo, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";
import { useNotification } from "@hooks/useNotification";
import useOutsideCallBack from "@hooks/useOutsideCallBack";

import { BLANK_PROFILE_PICTURE } from "@helpers/_constants";
import { diacriticSensitiveRegex } from "@helpers/_functions";
import { NotificationUtil } from "@utils/notification.utils";

import Avatar from "./Avatar";
import Button from "./Button";
import CheckboxSimple from "./CheckboxSimple";
import SearchInput from "./SearchInput";

/**
 *
 * @param {object} props
 * @param {Object} props.option
 * @param {boolean=} props.selected
 * @param {boolean=} props.noRadio
 * @returns
 */
const NotifyBoxOption = ({ option: item, selected, noRadio }) => (
  <CheckboxSimple
    id={item.id}
    checked={selected}
    noRadio={noRadio}
    label={
      <Avatar
        src={item.imgEmploye || item.avatar || BLANK_PROFILE_PICTURE}
        name={item.fullName}
        textStyle="arh-font-poppins arh-font-normal arh-text-xs arh-text-black"
      />
    }
    // color={props.color}
  />
);

const multiple = true;

export default function NotifyboxEmploye({
  lists = [],
  user,
  placeholder = "",
  classInput,
}) {
  const { t: translate } = useTranslation("hours.vacances");
  const [selectedEmployes, setSelectedEmployes] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { showSuccess, showError } = useNotification();

  const filteredRecruiters = useMemo(() => {
    if (!Array.isArray(lists)) {
      console.error("Listbox error: type of lists should be an array: ", {
        lists,
      });
      return [];
    }
    if (!searchValue || searchValue === "@") {
      return lists;
    }
    const __searchValue = searchValue.startsWith("@")
      ? searchValue.substring(1)
      : searchValue;
    const searchSplitted = __searchValue
      .toUpperCase()
      .split(/\s+/)
      .map((partialWord) => diacriticSensitiveRegex(partialWord));
    return lists.filter((recruiter) => {
      const recruiterName = diacriticSensitiveRegex(
        recruiter.fullName.toUpperCase(),
      );
      return searchSplitted.every((partialWord) =>
        recruiterName.includes(partialWord),
      );
    });
  }, [lists, searchValue, diacriticSensitiveRegex]);

  useEffect(() => {
    if (selectedEmployes.length) setSearchValue(selectedEmployes[0].fullName);
  }, [selectedEmployes]);

  const { wrapperRef } = useOutsideCallBack(() => {
    setSearchValue("");
  });

  const sendNotification = () => {
    if (user) {
      NotificationUtil.recruitersTagged(user, selectedEmployes[0].id)
        .then(() => {
          showSuccess("Une notification vient d'être envoyée à votre collègue");
          setSearchValue("");
        })
        .catch((error) => {
          console.log(error);
          showError("error");
        });
    }
  };

  const handleChangeSearch = (value) => {
    if (selectedEmployes?.length) setSelectedEmployes([]);
    setSearchValue(value);
  };

  return (
    <Listbox
      value={selectedEmployes}
      onChange={setSelectedEmployes}
      name="notifiedEmployes"
      multiple={multiple}
    >
      <div className=" arh-relative arh-w-full" ref={wrapperRef}>
        <Listbox.Button
          className="arh- arh-relative arh-cursor-pointer arh-rounded arh-border-0 arh-border-solid arh-border-[#ECECEC] arh-bg-white arh-text-left arh-font-poppins arh-text-xs arh-font-normal placeholder:arh-text-[#8B8F91] focus:arh-outline-none focus-visible:arh-border-[#ECECEC] focus-visible:arh-ring-2 focus-visible:arh-ring-[#ECECEC] focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor"
          as="div"
        >
          <SearchInput
            placeholder={translate(placeholder)}
            value={searchValue}
            onChange={handleChangeSearch}
            classInput={classInput}
          />
        </Listbox.Button>
        {searchValue && (
          <Listbox.Options
            static
            className="arh-absolute arh-right-0 arh-z-[6] arh-mt-1 arh-max-h-60 arh-w-full arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-px-4 arh-py-[0.938rem] arh-text-xs arh-shadow-xs desktop-mini:arh-w-[150%]"
          >
            {!selectedEmployes?.length ? (
              filteredRecruiters.map((recruiter) => (
                <Listbox.Option
                  key={recruiter._id}
                  className="arh-relative arh-cursor-pointer arh-select-none arh-list-none  arh-truncate arh-py-1.5 arh-font-poppins arh-text-xs arh-font-normal arh-text-darkColor"
                  value={recruiter}
                >
                  <NotifyBoxOption
                    option={recruiter}
                    selected={selectedEmployes.some(
                      (employe) => employe._id === recruiter._id,
                    )}
                    noRadio={true}
                  />
                </Listbox.Option>
              ))
            ) : (
              <>
                {selectedEmployes.map((recruiter) => (
                  <Listbox.Option
                    key={recruiter._id}
                    className="arh-relative arh-cursor-pointer arh-select-none arh-list-none  arh-truncate arh-py-1.5 arh-font-poppins arh-text-xs arh-font-normal arh-text-darkColor"
                    value={recruiter}
                  >
                    <NotifyBoxOption
                      option={recruiter}
                      // optionLabel={translateAndShow(option[optionTextAttribut])}
                      selected={selectedEmployes.some(
                        (employe) => employe._id === recruiter._id,
                      )}
                      noRadio={true}
                    />
                  </Listbox.Option>
                ))}
                <span className="arh-block arh-h-px arh-bg-[#ECEDEF]"></span>
                <div className="arh-mt-4 arh-flex arh-w-full arh-items-center arh-justify-between arh-space-x-4">
                  <Button
                    variant="outlined"
                    className="!arh-w-fit"
                    onClick={() => setSearchValue("")}
                  >
                    {translate("Annuler")}
                  </Button>
                  <Button className="!arh-w-fit" onClick={sendNotification}>
                    {translate("Envoyer")}
                  </Button>
                </div>
              </>
            )}
          </Listbox.Options>
        )}
      </div>
    </Listbox>
  );
}

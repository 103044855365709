import BaseService from "./base.service";
import http from "../http-common";
class PasswordService extends BaseService {
  constructor() {
    super("password");
  }
  getResetPassword(id) {
    return http.get(`${this.path}/reset/init/${id}`);
  }
  initPassword(reset) {
    return http.post(`${this.path}/init/`, reset);
  }
  sendChangePasswordLink(dataUser) {
    return http.post(`${this.path}/sendChangePasswordLink/`, dataUser);
  }
}

export default new PasswordService();

import React, { memo } from "react";

import { useTranslation } from "react-i18next";

import "@css/components/input.css";

import ResetFilter from "@components/tailwindComponents/ResetFilter";

const className = {
  normal:
    "arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
  invalid:
    "arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput",
};

const InputArh = (props) => {
  const { t: translate } = useTranslation("components.filtre");

  return (
    <form
      className="arh-relative arh-w-full"
      autoComplete="off"
      onSubmit={(event) => event.preventDefault()}
    >
      <input
        type={props.type || "text"}
        onClick={props.onClick}
        onChange={props.onChange}
        className={`arh-w-full arh-rounded arh-border arh-border-solid arh-py-3 arh-pl-4 arh-pr-10 arh-font-poppins arh-text-xs arh-font-normal placeholder:!arh-text-xs placeholder:arh-text-[#757A80] disabled:arh-cursor-none disabled:arh-border-greyDisabledBorder disabled:arh-bg-greyDisabled disabled:arh-text-greyDisabledText 
                  disabled:placeholder:arh-text-greyDisabledText ${props.invalid ? className["invalid"] : className["normal"]} ${props.class} ${props.classInputLoup}`}
        value={props.value}
        ref={props.refs}
        name={props.name}
        id={props.id}
        placeholder={translate(props.text)}
        readOnly={props.readOnly}
        onBlur={props.onBlur}
        data-testid={props.testId}
        autoComplete={props.autoComplete || "on"}
        onKeyDown={props.onKeyDown}
        onKeyPress={props.onKeyPress}
        disabled={props.disabled}
      />
      {props?.src ? (
        <img
          alt="icone"
          onClick={props.clickEye}
          src={props.src}
          className={`arh-absolute arh-right-4 arh-top-1/2 arh-h-4 arh-w-4 -arh-translate-y-1/2 ${props.className}`}
        />
      ) : (
        ""
      )}
      {props.showReset && props.value && (
        <ResetFilter onClick={props.onClickReset} />
      )}
    </form>
  );
};
export default memo(InputArh);

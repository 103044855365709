import React, { memo } from "react";
import { SvgLoadingForButton } from "@routes/IconeSvg";

const Button = ({
  modifier,
  label,
  leftIcon,
  rightIcon,
  onClick,
  wait,
  disabled,
  styleBtn,
  classLeftIcon,
  classRightIcon,
  onMouseEnter,
  onMouseLeave,
  showSpinnerLoading
}) => {
  /*
   * modifier { width, background-color, border-color, font-weight, font-size, text-color, hover effect ...}
   */
  const _showSvgSpinner = wait && showSpinnerLoading;
  return (
    <button
      type="button"
      className={`arh-w-full arh-flex arh-justify-center arh-items-center arh-py-3 arh-font-poppins arh-font-medium arh-border arh-border-solid arh-rounded-[5px] arh-transition-background arh-duration-200 arh-ease-in arh-tracking-wide
        ${wait && "!arh-cursor-wait"} ${disabled ?
          "arh-bg-greyDisabled arh-border-greyDisabled arh-text-greyDisabledText arh-cursor-not-allowed"
          : modifier}`}
      onClick={onClick}
      disabled={disabled || wait}
      style={styleBtn}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      { _showSvgSpinner ? SvgLoadingForButton() : "" }
      {leftIcon && (
        <span className={`${classLeftIcon || "arh-mr-2"}`}>{leftIcon}</span>
      )}
      {label}
      {rightIcon && (
        <span className={`${classRightIcon || "arh-ml-2"}`}>{rightIcon}</span>
      )}
    </button>
  );
};

export default memo(Button);

import BaseService from "./base.service";
import http from "../http-common";
import {
  customizeError,
  checkUserId,
  getResponseData,
} from "../helpers/_functions";

class Conge extends BaseService {
  constructor() {
    super("conges");
  }
  getByEmployer(id, params = {}) {
    id = checkUserId(id);
    return http
      .get(`${this.path}/employe/${id}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
  getValidationDate(id, params = {}) {
    id = checkUserId(id);
    return http
      .get(`${this.path}/validations/${id}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
  UpdateStatus(conge) {
    return http.put(`${this.path}/updatestatus/${conge._id}`, conge);
  }
  SingleConge(id) {
    return http.get(`${this.path}/single/${id}`);
  }
  createVacations(conge) {
    return http.post(`${this.path}/create`, conge)
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
  deleteConge(idConge, params = {}) {
    return http.delete(`${this.path}/delete/${idConge}`, { params });
  }
  getByEnterpriseAndFilter(idAdminEnterprise, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http
      .get(`${this.path}/enterpriseFilter/${idAdminEnterprise}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
  getByEmployerAndFilter(employerId, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http
      .get(`${this.path}/employerFilter/${employerId}`, { params })
      .then((response) => getResponseData(response))
      .catch((err) => {
        return Promise.reject(customizeError(err));
      });
  }
  updateRemainingDays(item) {
    return http.put(`${this.path}/updateRemainingDays/${item._id}`, item);
  }
  updateConges(items) {
    return http.post(`${this.path}/updateconges`, items);
  }
  sendFilesToDocs(items) {
    return http.post(`${this.path}/send-files-to-docs`, items);
  }
}

export default new Conge();

import { makeActionHandler } from "./_reducerUtils";

export const developmentPlanReducer = {
  initialState: {
    developmentPlan: {
      list: [],
      isLoaded: false,
    },
    evaluationNote: {
      list: [],
    },
    employeeObjective: {
      competenceList: [],
      performanceList: [],
      isLoaded: false,
    },
    enterpriseObjective: {
      competenceList: [],
      performanceList: [],
      assignedEmployee: false,
    },
    individualObjectiveType: {
      list: [],
      isLoaded: false,
    },
    individualObjectivePeriod: {
      list: [],
      isLoaded: false,
    },
    enterpriseIndividualObjective: {
      list: [],
      isLoaded: false,
      assignedEmployee: null,
    },
    employeeIndividualObjective: {
      list: [],
      isLoaded: false,
    },
  },
  handlers: {
    "developmenPlan/SET_DEVELOPMENT_PLAN": makeActionHandler("developmentPlan"),
    "developmenPlan/SET_EVALUATION_NOTE": makeActionHandler("evaluationNote"),

    "developmenPlan/SET_EMPLOYEE_OBJECTIVE":
      makeActionHandler("employeeObjective"),
    "developmenPlan/SET_ENTEPRISE_OBJECTIVE": makeActionHandler(
      "enterpriseObjective",
    ),
    "developmenPlan/SET_INDIVIDUAL_OBJECTIVE_TYPE": makeActionHandler(
      "individualObjectiveType",
    ),
    "developmenPlan/SET_INDIVIDUAL_OBJECTIVE_PERIOD": makeActionHandler(
      "individualObjectivePeriod",
    ),
    "developmenPlan/SET_ENTERPRISE_INDIVIDUAL_OBJECTIVE": makeActionHandler(
      "enterpriseIndividualObjective",
    ),
    "developmenPlan/SET_EMPLOYEE_INDIVIDUAL_OBJECTIVE": makeActionHandler(
      "employeeIndividualObjective",
    ),

    "developmenPlan/RESET_ALL": () => developmentPlanReducer.initialState,
    RESET_ALL: () => developmentPlanReducer.initialState,
  },
};

export default developmentPlanReducer;

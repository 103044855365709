import React from "react";
import { Disclosure } from "@headlessui/react";

export default function DisclosureCollapse({ title, details }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`arh-w-full arh-flex arh-justify-between arh-items-center arh-border-0 arh-bg-transparent ${
              open && "arh-border-b-0 arh-rounded-bl-none arh-rounded-br-none"
            }`}
          >
            <div className="arh-underline">{title}</div>
          </Disclosure.Button>
          <Disclosure.Panel
            className={`arh-max-w-xs arh-border  arh-border-solid arh-border-greyBorderInput arh-rounded-md arh-p-4 arh-font-poppins arh-text-black arh-font-normal ${
              open && "arh-rounded-tl-none arh-rounded-tr-none"
            }`}
          >
            {details}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

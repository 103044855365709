import BaseService from "./base.service";
import http from "../http-common";
class SubGroupService extends BaseService {
  constructor() {
    super("sous-groupe");
  }
  getSubGroupByGroupId(id) {
    return http.get(`${this.path}/list/${id}`);
  }
  getById(id) {
    return http.get(`${this.path}/get/${id}`);
  }
}

export default new SubGroupService();

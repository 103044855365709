import React from "react";

/**
 * Returns a memoized version of a component type.
 *
 * @template T - The component type.
 * @param {T} Component - The component to memoize.
 * @param {function(object, object): boolean} [propsAreEqual] - Optional function to compare previous and next props for equality.
 * @returns {T}
 */
export function reactMemo(Component, propsAreEqual) {
  // @ts-ignore
  return React.memo(Component, propsAreEqual);
}

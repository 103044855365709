import { combineObjectWithoutCloning } from "@helpers/_functions";

export const calendarData = {
  initialState: {
    //calendar view
    viewCalendar: "month",
    // Heures travaillées
    workingHoursEnterpriseData: [],
    dateIndexWorkingHoursEnterpriseData: [],
    // Mon horaire
    horaryEmployerWithLunchMonthData: [],
    horaryEmployerWithoutLunchMonthData: [],
    indexLoadingMonHoraire: [],
    // Planification
    horaryEnterpriseWithLunchMonthData: [],
    horaryEnterpriseWithoutLunchMonthData: [],
    indexLoadingPlanification: []
  },
  handlers: {
    "SET_CALENDAR_VIEW": (state, payload) => combineObjectWithoutCloning(state, { viewCalendar: payload }),
    "WORKING_HOUR_ENTERPRISE_DATA": (state, payload) => combineObjectWithoutCloning(state, { workingHoursEnterpriseData: payload }),
    "DATE_INDEX_WORKIN_HOUR_ENTERPRISE_DATA": (state, payload) => combineObjectWithoutCloning(state, { dateIndexWorkingHoursEnterpriseData: payload }),
    "HORARY_EMPLOYER_WITH_LUNCH_MONTH_DATA": (state, payload) => combineObjectWithoutCloning(state, { horaryEmployerWithLunchMonthData: payload }),
    "HORARY_EMPLOYER_WITHOUT_LUNCH_MONTH_DATA": (state, payload) => combineObjectWithoutCloning(state, { horaryEmployerWithoutLunchMonthData: payload }),
    "INDEX_LOADING_MON_HORAIRE": (state, payload) => combineObjectWithoutCloning(state, { indexLoadingMonHoraire: payload }),
    "HORARY_ENTERPRISE_WITH_LUNCH_MONTH_DATA": (state, payload) => combineObjectWithoutCloning(state, { horaryEnterpriseWithLunchMonthData: payload }),
    "HORARY_ENTERPRISE_WITHOUT_LUNCH_MONTH_DATA": (state, payload) => combineObjectWithoutCloning(state, { horaryEnterpriseWithoutLunchMonthData: payload }),
    "INDEX_LOADING_PLANIFICATION": (state, payload) => combineObjectWithoutCloning(state, { indexLoadingPlanification: payload }),
    "RESET_ALL": () => calendarData.initialState
  }
};

export default calendarData;

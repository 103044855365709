import BaseService from "./base.service";
import http from "../http-common";
class CertificationFileService extends BaseService {
  constructor() {
    super("certification-file");
  }
  uploadCertificationFile(data) {
    return http.post(`${this.path}/upload-file`, data, {});
  }
}

export default new CertificationFileService();

import React from "react";

import BaseListBox from "./BaseListBox";
import CheckboxSimple from "./CheckboxSimple";

/**
 * @typedef {Object} Props
 * @property {import("./CheckboxSimple").CheckBoxSimpleVariant=} color
 * @property {string=} customClass Class pour customiser le label du checkbox
 * @property {string=} className Class pour customiser le conteneur du checkbox
 */

/**
 *
 * @param {(import("./BaseListBox").BaseListBoxProps & Props)} props
 * @returns
 */
export default function ListboxSimple(props) {
  const { color, customClass, className, ...baselistboxProps } = props;

  return (
    <BaseListBox {...baselistboxProps}>
      {({
        option,
        selected,
        optionLabel,
        noRadio,
        recurrenceListBox,
        withTooltip,
      }) => (
        <CheckboxSimple
          id={option._id}
          label={optionLabel}
          checked={selected}
          noRadio={Boolean(noRadio)}
          variant={color}
          recurrenceListBox={recurrenceListBox}
          withTooltip={withTooltip}
          customClass={customClass}
          className={className}
        />
      )}
    </BaseListBox>
  );
}

import { EmployeUtils } from "./EmployeUtils";
import moment from "moment-timezone";
import uniqueid from "uniqueid";

export class CalendarUtils {
  constructor() {
    this.options = {
      includeEmployeInfo: false,
      combineTitle: false,
      calculateSum: false,
      externalIndexing: false,
      linear: null,
      noLayering: false,
    };

    this.plannedHours = [];
    this.eventSum = 0;
    this.events = {
      withLunch: [],
      withoutLunch: [],
      rawWithoutLunch: [],
    };
  }

  setOptions(options) {
    this.options = {
      ...this.options,
      ...options,
    };
  }

  setPlannedHours(plannedHours) {
    this.plannedHours = plannedHours;
  }

  static isEqualTimeSlots(date1, date2) {
    let tempDate1 = moment(date1).set("second", 0);
    let tempDate2 = moment(date2).set("second", 0);
    return moment(tempDate1._d).isSame(tempDate2._d);
  }

  slotDuration = (slot) => {
    return moment
      .duration(moment(slot.end).diff(moment(slot.start)))
      .as("hours");
  };

  // @deprecated
  async getEvents() {
    try {
      if (!this.plannedHours) {
        throw new Error("Aucun horraire prévu de trouvé");
      }
      this.events = {
        withLunch: [],
        withoutLunch: [],
        rawWithoutLunch: [],
      };
      this.eventSum = 0;
      for (const plannedHour of this.plannedHours) {
        /* -- Get needed employe info -- */
        const employeName =
          plannedHour.Employer[0].personnalInformation[0].legalFirstName +
          " " +
          plannedHour.Employer[0].personnalInformation[0].legalName;
        const employePhoto =
          plannedHour.Employer?.[0]?.images?.[0]?.location ||
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

        const event = {
          recurrenceId: plannedHour.recurrenceId,
          desc: plannedHour.desc,
          isTeleWork: plannedHour.isTeleWork,
          workingPlace: plannedHour.workingPlace,
          users: [
            {
              plannedHourId: plannedHour._id,
              username: employeName,
              photo: employePhoto,
              employeId: plannedHour.Employer[0]._id,
            },
          ],
          title: plannedHour.title,
        };

        if (this.options.includeEmployeInfo) {
          const [groupAndSubGroup, emplacements] = await Promise.all([
            EmployeUtils.getGroupAndSubgroup(plannedHour.Employer[0]._id),
            EmployeUtils.getEmplacementByEmployer(plannedHour.Employer[0]._id),
          ]);
          event.users = [
            {
              ...event.users[0],
              groupAndSubGroup,
              emplacements,
              employeId: plannedHour.Employer[0]._id,
            },
          ];
        }
        event.isTeleWork = plannedHour.isTeleWork;
        /* -- find start and end date -- */
        event.start = new Date(plannedHour.start);
        event.end = new Date(plannedHour.end);
        this.insertEventWithoutLunch(event);

        const dateDifference =
          moment(plannedHour.end.substring(0, 10)).diff(
            plannedHour.start.substring(0, 10),
            "days"
          ) + 1;
        const lunchStart = plannedHour?.lunchHour?.startLunch;
        const lunchEnd = plannedHour?.lunchHour?.endLunch;
        const endTime = this.getTimeFromDate(plannedHour.end);
        const startLunchTime = this.getTimeFromDate(lunchStart);
        const endLunchTime = this.getTimeFromDate(lunchEnd);
        let intersectionBefore = null;

        for (
          let dateRepetition = 0;
          dateRepetition < dateDifference;
          dateRepetition++
        ) {
          intersectionBefore = false;
          let incrementedDate = moment(plannedHour.start)
            .add(dateRepetition, "days")
            .toDate();

          /* -- all day -- */
          if (!lunchStart) {
            if (dateRepetition === 0) {
              event.start = incrementedDate;
            } else {
              event.start = moment(incrementedDate)
                .hours(0)
                .minutes(0)
                .toDate();
            }
            if (dateRepetition === dateDifference - 1) {
              event.end = moment(incrementedDate)
                .hours(endTime.hours)
                .minutes(endTime.minutes)
                .toDate();
            } else {
              event.end = moment(incrementedDate)
                .hours(23)
                .minutes(59)
                .toDate();
            }
            this.insertEventWithLunch(event);
            continue;
          }

          /* -- Morning part -- */
          event.hasLunch = true; // Déboggage superposition des times slots
          event.uniqueid = uniqueid(); // Déboggage séparation horaire avec pause repas
          if (dateRepetition === 0) {
            event.start = incrementedDate;
            //intersection before
            if (
              moment(incrementedDate)
                .hours(startLunchTime.hours)
                .minutes(startLunchTime.minutes)
                .isSameOrBefore(incrementedDate)
            ) {
              intersectionBefore = true;
            }
          } else {
            event.start = moment(incrementedDate).hours(0).minutes(0).toDate();
          }

          if (!intersectionBefore) {
            event.end = moment(incrementedDate)
              .hours(startLunchTime.hours)
              .minutes(startLunchTime.minutes)
              .toDate();

            let dateEnd = moment(incrementedDate)
              .hours(endTime.hours)
              .minutes(endTime.minutes)
              .toDate();

            //intersection after
            if (
              dateRepetition === dateDifference - 1 &&
              moment(incrementedDate)
                .hours(endLunchTime.hours)
                .minutes(endLunchTime.minutes)
                .isSameOrAfter(dateEnd)
            ) {
              if (
                moment(incrementedDate)
                  .hours(startLunchTime.hours)
                  .minutes(startLunchTime.minutes)
                  .isAfter(dateEnd)
              ) {
                event.end = dateEnd;
              }
              this.insertEventWithLunch(event);
              continue;
            }
            this.insertEventWithLunch(event);
          }

          /* -- Afternoon part -- */
          event.start = moment(incrementedDate)
            .hours(endLunchTime.hours)
            .minutes(endLunchTime.minutes)
            .toDate();

          if (
            intersectionBefore &&
            moment(incrementedDate)
              .hours(endLunchTime.hours)
              .minutes(endLunchTime.minutes)
              .isBefore(incrementedDate)
          ) {
            event.start = incrementedDate;
          }

          if (dateRepetition === dateDifference - 1) {
            event.end = moment(incrementedDate)
              .hours(endTime.hours)
              .minutes(endTime.minutes)
              .toDate();
          } else {
            event.end = moment(incrementedDate).hours(23).minutes(59).toDate();
          }
          this.insertEventWithLunch(event);
        }
      }
    } catch (err) {
      console.error(err);
    }

    let output = {
      eventWithLunch: this.options.externalIndexing
        ? this.events.withLunch
        : this.constructor.setEventsIndex(this.events.withLunch),
      eventWithoutLunch: this.options.combineTitle
        ? this.getWithCombinedTitle()
        : this.events.withoutLunch,
      rawWithoutLunch: this.events.rawWithoutLunch,
    };

    if (this.options.calculateSum) {
      output.total = this.eventSum;
    }

    return output;
  }

  insertEventWithoutLunch({ ...event }) {
    this.events.rawWithoutLunch.push({ ...event });
    if (this.options.noLayering) {
      const eventStartDate = moment(event.start)
        .set({ h: 0, m: 0, s: 0 })
        .toDate();
      const eventEndDate = moment(event.end).set({ h: 0, m: 0, s: 0 }).toDate();
      const dateDifference =
        moment(eventEndDate).diff(eventStartDate, "days") + 1;
      let eventStart = moment(event.start).toDate();

      for (
        let dateRepetition = 0;
        dateRepetition < dateDifference;
        dateRepetition++
      ) {
        let actualDate = moment(eventStart)
          .add(dateRepetition, "days")
          .toDate();
        let sameDateEvents = this.events.withoutLunch.filter(
          (registeredEvent) => {
            return moment(actualDate).isBetween(
              registeredEvent.start,
              registeredEvent.end,
              "days",
              "[]"
            );
          }
        );
        for (let sameDateEvent of sameDateEvents) {
          this.cutEventWithoutLunch(sameDateEvent, actualDate);
        }
        if (sameDateEvents.length) {
          this.cutEventWithoutLunch(event, actualDate);
        }
      }
    }
    this.events.withoutLunch.push(event);
  }

  cutEventWithoutLunch(eventToCut, cutDate) {
    if (moment(eventToCut.start).isSame(eventToCut.end, "days")) {
      return null;
    }
    //---
    //-
    if (moment(eventToCut.start).isSame(cutDate, "days")) {
      let other = {
        ...eventToCut,
        end: moment(cutDate).hours(23).minutes(59).toDate(),
      };
      this.events.withoutLunch.push(other);
      eventToCut.start = moment(cutDate)
        .add("days", 1)
        .hours(0)
        .minutes(0)
        .toDate();
    }

    //---
    // -
    else if (!moment(eventToCut.end).isSame(cutDate, "days")) {
      let otherOne = {
        ...eventToCut,
        end: moment(cutDate).subtract("days", 1).toDate(),
      };

      let otherTwo = {
        ...eventToCut,
        start: moment(cutDate).hours(0).minutes(0).toDate(),
        end: moment(cutDate).hours(23).minutes(59).toDate(),
      };
      this.events.withoutLunch.push(otherOne, otherTwo);
      eventToCut.start = moment(cutDate)
        .add("days", 1)
        .hours(0)
        .minutes(0)
        .toDate();
    }

    //---
    //  -
    else {
      let other = {
        ...eventToCut,
        end: moment(cutDate).subtract("days", 1).set({ h: 23, m: 59 }).toDate(),
      };
      this.events.withoutLunch.push(other);
      eventToCut.start = moment(cutDate).hours(0).minutes(0).toDate();
    }
  }

  insertEventWithLunch({ ...event }) {
    if (this.options.calculateSum && event.start && event.end) {
      const startDate = moment(event.start);
      const endDate = moment(event.end);
      let duration = moment.duration(endDate.diff(startDate));
      this.eventSum += duration.asHours();
    }

    if (this.options.linear) {
      event.linear = this.options.linear;
    }

    this.events.withLunch.push({ ...event });
  }

  static getSameEvent = (actualEvent, event) => {
    const hourDifference = moment
      .duration(moment(event.end).diff(event.start))
      .asHours();
    return (
      this.isEqualTimeSlots(actualEvent.start, event.start) &&
      this.isEqualTimeSlots(actualEvent.end, event.end) &&
      parseInt(hourDifference) > event.users.length &&
      actualEvent.isTeleWork === event.isTeleWork
    );
  };

  //combine users for same events (if space is free)
  static combineSameDates(initialEvents) {
    let outputEvents = [];
    for (let actualEvent of initialEvents) {
      const sameHourEvent = outputEvents.find((ev) => {
        return this.getSameEvent(actualEvent, ev);
      });
      actualEvent.start = new Date(actualEvent.start);
      actualEvent.end = new Date(actualEvent.end);
      if (sameHourEvent) {
        sameHourEvent.users = [...sameHourEvent.users, actualEvent.users[0]]; //avoid reference copy
      } else {
        outputEvents.push({ ...actualEvent });
      }
    }

    return outputEvents;
  }

  //combine event title if events are on the same day
  getWithCombinedTitle() {
    let outputEvents = [];
    for (let event of this.events.withoutLunch) {
      // let sameDayEvent = outputEvents.find(oe => moment(oe.start).isSame(event.start, 'days'));
      // if(sameDayEvent){
      // 	sameDayEvent.title += '\n' + this.getEventTitle(event);
      // 	continue;
      // }
      outputEvents.push({
        ...event,
        title: this.getEventTitle(event),
      });
    }
    return outputEvents;
  }

  getEventTitle(event) {
    return (
      moment(event.start).format("HH:mm") +
      " - " +
      moment(event.end).format("HH:mm")
    );
  }

  static setEventsIndex(initialEvents) {
    let outputEvents = [];
    for (let actualEvent of initialEvents) {
      let findSameDateEvent = (e) =>
        e.eventIndex === index &&
        (moment(actualEvent.start).isBetween(e.start, e.end, null, "[]") ||
          moment(actualEvent.end).isBetween(e.start, e.end, null, "[]") ||
          moment(e.start).isBetween(
            actualEvent.start,
            actualEvent.end,
            null,
            "[]"
          ) ||
          moment(e.end).isBetween(
            actualEvent.start,
            actualEvent.end,
            null,
            "[]"
          ));
      let index = 0;
      while (1) {
        let event = outputEvents.find(
          (e) =>
            e.uniqueid &&
            actualEvent.uniqueid &&
            e.uniqueid === actualEvent.uniqueid
        );
        if (event) {
          outputEvents.push({ ...actualEvent, eventIndex: event?.eventIndex });
          break;
        }

        let dateOnIndex = outputEvents.find(findSameDateEvent);
        if (!dateOnIndex) {
          outputEvents.push({ ...actualEvent, eventIndex: index });
          break;
        }
        index += 1;
      }
    }

    return outputEvents;
  }

  static longDateSeparation(longHour) {
    const diffDate = moment(longHour.end).diff(longHour.start, "days") + 1;

    let hours = [];
    if (diffDate > 1) {
      for (let d = 0; d < diffDate; d++) {
        const incrementedDate = moment(longHour.start).add(d, "days");
        const endTime = getTimeFromDate(longHour.end);
        const hour = {
          ...longHour,
          start: moment(incrementedDate).toDate(),
          end: moment(incrementedDate)
            .hours(endTime.hours)
            .minutes(endTime.minutes)
            .toDate(),
        };
        // Convert hour lunch to incremented date
        if (hour.lunchHour) {
          const startLunchTime = getTimeFromDate(hour.lunchHour.startLunch);
          const endLunchTime = getTimeFromDate(hour.lunchHour.endLunch);
          const lunchHour = {
            startLunch: moment(incrementedDate)
              .hours(startLunchTime.hours)
              .minutes(startLunchTime.minutes)
              .toDate(),
            endLunch: moment(incrementedDate)
              .hours(endLunchTime.hours)
              .minutes(endLunchTime.minutes)
              .toDate(),
          };
          hour.lunchHour = lunchHour;
        }
        // const hoursWithLunch = duplicateForLunch(hour);

        hours = hours.concat(hour);
      }
    } else {
      // const hourWithLunch = duplicateForLunch(longHour);
      return hours.concat(longHour);
    }
    return hours;
  }

  getTimeFromDate(date) {
    return {
      hours: moment(date).hours(),
      minutes: moment(date).minutes(),
    };
  }
}

function getTimeFromDate(date) {
  return {
    hours: moment(date).hours(),
    minutes: moment(date).minutes(),
  };
}

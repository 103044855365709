import { makeActionHandler } from "./_reducerUtils";

export const materialsData = {
  initialState: {
    statistics: {
      lists: {
        materialPerCategory: [],
        materialsComingToEnd: [],
        materialsPerState: [],
        materialsPerStatus: [],
        stolenOrInusable: [],
        totals: [],
      },
      isLoaded: false,
      isFilterApplied: false,
      filter: {},
    },
    inventory: {
      list: [],
      isLoaded: false,
      loadingElementsIds: [],
    },
    assignedMaterial: {
      list: [],
      isLoaded: false,
      employerId: null,
    },
  },
  handlers: {
    "materials/SET_STATISTICS": makeActionHandler("statistics"),
    "materials/SET_INVENTORY": makeActionHandler("inventory"),
    "materials/SET_ASSIGNED_MATERIALS": makeActionHandler("assignedMaterial"),

    RESET_ALL: () => materialsData.initialState,
  },
};

export default materialsData;

import { getResponseQuery } from "@helpers/_functions";

import http from "../../http-common";
import BaseService from "../base.service";

class IndividualObjective extends BaseService {
  constructor() {
    super("individual-objective");
  }

  getByEmployee(employeeId, enterpriseId, { visibleOnly = false } = {}) {
    const params = { employeeId, enterpriseId, visibleOnly };
    return getResponseQuery(
      http.get(`${this.path}/get-by-employee`, { params }),
    );
  }
}

export default new IndividualObjective();

import React, { Component } from "react";
import "@css/components/input.css";
export default class InputInaccessible extends Component {
  render = () => {
    return (
      <input
        type={this.props.type}
        onClick={this.props.onClick}
        onChange={this.props.onChange}
        className={"regular " + this.props.class}
        value={this.props.value}
        ref={this.props.refs}
        name={this.props.name}
        id={this.props.id}
        placeholder={this.props.text}
        onBlur={this.props.onBlur}
        autoComplete="new-email"
        onPaste={this.props.onPaste}
        onCopy={this.props.onCopy}
        readOnly
      />
    );
  };
}

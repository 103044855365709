import { permissions } from "@pages/layouts/utils/Permissions.lists";

export const rulesMiddleware = (store) => (next) => (action) => {
  if (action?.rules) {
    const rules = action.rules;
    let allowedPermissions =
      (typeof rules === "string" && permissions[rules]) ||
      (Array.isArray(rules) && rules) ||
      [];
    const { userConnected } = store.getState();
    if (
      userConnected?.permissions?.some((p) => allowedPermissions.includes(p))
    ) {
      return next(action);
    }
    return;
  }
  return next(action);
};

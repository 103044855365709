import { getResponseQuery } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

class EvaluationObjective extends BaseService {
  constructor() {
    super("evaluation-objectives");
  }

  createMany(reqBody) {
    return getResponseQuery(http.post(`${this.path}/create-many`, reqBody));
  }

  /**
   *
   * @param {string} assignedEmployee
   * @param {string} enterpriseId
   * @param {{criterias?: string[]}} params
   * @returns
   */
  getByEmployee(assignedEmployee, enterpriseId, params = { criterias: [] }) {
    params.criterias = JSON.stringify(params.criterias);
    return getResponseQuery(
      http.get(
        `${this.path}/get-by-employee/${assignedEmployee}/${enterpriseId}`,
        { params },
      ),
    );
  }

  getByEvaluationCriteria(evaluationCriteriaId, assignedEmployee) {
    return getResponseQuery(
      http.get(
        `${this.path}/get-by-evaluation-criteria/${evaluationCriteriaId}/${assignedEmployee}`,
      ),
    );
  }
}

export default new EvaluationObjective();

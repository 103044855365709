import { makeActionHandler } from "./_reducerUtils";

const connectedEmployeData = {
  initialState: {
    personnalInfo: {
      data: {},
      isLoaded: false,
    },
    employeSalary: {
      data: {},
      isLoaded: false,
    },
    employeEmplacement: {
      list: [],
      isLoaded: false,
    },
    employeGroupe: {
      list: [],
      isLoaded: false,
    },
    employeCourseAndSalary: {
      list: [],
      isLoaded: false,
    },
    employeCertification: {
      list: [],
      isLoaded: false,
    },
    employeFormation: {
      list: [],
      isLoaded: false,
    },
    employeConge: {
      list: [],
      isLoaded: false,
      isFiltered: false,
      isLoading: false,
      filter: {},
      dataAfter: 0,
      count: 0,
    },
    assignedTask: {
      list: [],
      isLoaded: false,
      filter: {},
      isFiltered: false,
      isRefreshing: false,
      selectedEvaluationRequest: null,
      evaluationRequestList: [],
      onboardingTaskList: [],
      allEvaluationRequests: []
    },
  },
  handlers: {
    "connectedEmployeData/SET_PERSONNAL_INFO":
      makeActionHandler("personnalInfo"),
    "connectedEmployeData/SET_EMPLOYE_SALARY":
      makeActionHandler("employeSalary"),
    "connectedEmployeData/SET_EMPLOYE_EMPLACEMENT":
      makeActionHandler("employeEmplacement"),
    "connectedEmployeData/SET_EMPLOYE_GROUPE":
      makeActionHandler("employeGroupe"),
    "connectedEmployeData/SET_EMPLOYE_COURSE_AND_SALARY": makeActionHandler(
      "employeCourseAndSalary",
    ),
    "connectedEmployeData/SET_EMPLOYE_CERTIFICATION": makeActionHandler(
      "employeCertification",
    ),
    "connectedEmployeData/SET_EMPLOYE_FORMATION":
      makeActionHandler("employeFormation"),

    "connectedEmployeData/SET_EMPLOYE_CONGE": makeActionHandler("employeConge"),
    "connectedEmployeData/SET_ASSIGNED_TASK": makeActionHandler("assignedTask"),

    RESET_ALL: () => connectedEmployeData.initialState,
  },
};

export default connectedEmployeData;

import React, { Component } from "react";

import {
  SvgPasswordNoView,
  SvgPasswordView,
  SvgShapeDropdown,
} from "@routes/IconeSvg";

import "@css/components/input.css";

class InputArh extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.inputRef.current &&
      !this.inputRef.current.contains(event.target)
    ) {
      this.props.onClickOutside(this.inputRef.current.value, event.target);
    }
  };

  onChangeEmptyField = (event) => {
    if (event.target.value.trim() === "") {
      event.target.value = "";
    }
    this.props.onChange(event);
  };

  render = () => {
    return (
      <form autoComplete="off" className="arh-relative arh-w-full">
        <input
          type={this.props.type}
          onClick={this.props.onClick}
          onChange={this.onChangeEmptyField}
          onKeyDown={this.props.onKeyDown}
          onKeyPress={this.props.onKeyPress}
          className={
            "arh-w-full arh-rounded arh-border arh-border-solid arh-border-greyBorderInput arh-px-4 arh-py-3 arh-font-poppins !arh-text-xs arh-font-normal placeholder:arh-text-[#8B8F91] " +
            this.props.class
          }
          value={this.props.value}
          ref={this.props.onClickOutside ? this.inputRef : this.props.refs}
          name={this.props.name}
          id={this.props.id}
          placeholder={this.props.text}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          autoComplete={this.props.autoComplete || "off"}
          onPaste={this.props.onPaste}
          onCopy={this.props.onCopy}
          pattern={this.props.pattern}
          disabled={this.props.disabled}
          data-testid={this.props.testId}
          readOnly={this.props.readOnly}
          // autoFocus={Boolean(this.props.autoFocus)}
        />
        {this.props.type === "password" ? (
          <img
            alt="loupe"
            onClick={this.props.clickEye}
            className="input_view position_absolute"
            src={this.props.src ? SvgPasswordView() : SvgPasswordNoView()}
          />
        ) : (
          ""
        )}
        {this.props.dropdown ? (
          <div className={this.props.fadeIn ? "shape_Open" : "shapeNo_open"}>
            {SvgShapeDropdown()}
          </div>
        ) : (
          ""
        )}
      </form>
    );
  };
}

export default React.memo(InputArh);

import { getResponseQuery as _q } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

//const _q = getResponseQuery;

class OnboardingEmployeTask extends BaseService {
  constructor() {
    super("onboarding-employe-task");
  }

  createTask = (data) => _q(super.insert(data));

  updateTask = (taskId, task) =>
    _q(http.put(`${this.path}/update/${taskId}`, task));

  deleteTask = (taskId) => _q(super.deleteById(taskId));

  /**
   * Retrieves filtered tasks based on the provided filters and options.
   *
   * @param {Object} filters
   * @param {Object} [options={}]
   * @param {boolean} [options.separateAssignee=false]
   * @return {Promise}
   */
  getFilteredTasks = (filters = {}, options = {}) => {
    const enterpriseId = localStorage.getItem("enterpriseId");

    const params = {
      filters: JSON.stringify(filters),
      userId: localStorage.getItem("userId"),
      separateAssignee: options.separateAssignee || false,
    };

    return _q(
      http.get(`${this.path}/get-filtered-tasks/${enterpriseId}`, { params }),
    );
  };

  /**
   * @param {string} taskId
   * @param {{separateAssignee?: boolean}} [options={}]
   * @returns {Promise}
   */
  getById(taskId, options = {}) {
    return _q(http.get(`${this.path}/getById/${taskId}`, { params: options }));
  }

  cancelTask = (taskId) => {
    return _q(http.patch(`${this.path}/cancel-task/${taskId}`));
  };
}

export default new OnboardingEmployeTask();

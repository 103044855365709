import BaseService from "./base.service";
import http from "../http-common";
class EmployerDocumentService extends BaseService {
  constructor() {
    super("employe-documents");
  }
  getEmployeDocuments(id) {
    return http.get(`${this.path}/docs/${id}`);
  }
  getEmployeFiles(id) {
    return http.get(`${this.path}/files/${id}`);
  }
  getByEmployer(id) {
    return http.get(`${this.path}/getByEmployer/${id}`);
  }
  UploadDocument(dataFile) {
    return http.post(`${this.path}/upload`, dataFile);
  }
}

export default new EmployerDocumentService();

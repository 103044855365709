import BaseService from "../base.service";
import http from "../../http-common";
class DistanceService extends BaseService {
  constructor() {
    super("distance");
  }
  getAllDistances(id) {
    return http.get(`${this.path}/${id}`);
  }
  insert(data) {
    return http.post(`${this.path}/`, data);
  }
  updateById(data) {
    return http.put(`${this.path}/${data._id}`, data);
  }
 
}

export default new DistanceService();
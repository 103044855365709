import { combineObjectWithoutCloning } from "@helpers/_functions";
import moment from "moment-timezone";

export const reimbursmentsReducer = {
  initialState: {
    reimbursments: [],
    employerLists: [],
    enterpiseLists: [],
    exportLists: [],
    categories: [],
    taxes:[],
    employerFilter: {
      period: {
        startDate: moment().subtract(3, "month").toDate(),
        endDate: moment().endOf("day").toDate()
      }
    },
    enterpriseFilter: {
      period: {
        startDate: moment().subtract(3, "month").toDate(),
        endDate: moment().endOf("day").toDate()
      }
    },
    exportFilter: {
      period: {
        startDate: moment().subtract(3, "month").toDate(),
        endDate: moment().endOf("day").toDate()
      }
    },
    recentItineraries: [],
    itineraries: []
  },
  handlers: {
    "reimbursments/SET_LISTS": (state, payload) =>
      combineObjectWithoutCloning(state, { reimbursments: payload }),
    "reimbursments/SET_EMPLOYER_FILTER": (state, payload) =>
      combineObjectWithoutCloning(state, { employerFilter: payload }),
    "reimbursments/SET_ENTERPRISE_FILTER": (state, payload) =>
      combineObjectWithoutCloning(state, { enterpriseFilter: payload }),
    "reimbursments/SET_EXPORT_FILTER": (state, payload) =>
      combineObjectWithoutCloning(state, { exportFilter: payload }),
    "reimbursments/SET_CATEGORIES": (state, payload) =>
      combineObjectWithoutCloning(state, { categories: payload }),
    "reimbursments/SET_TAXES": (state, payload) =>
      combineObjectWithoutCloning(state, { taxes: payload }),
    "reimbursments/SET_EMPLOYER_LISTS": (state, payload) =>
      combineObjectWithoutCloning(state, { employerLists: payload }),
    "reimbursments/SET_ENTERPRISE_LISTS": (state, payload) =>
      combineObjectWithoutCloning(state, { enterpiseLists: payload }),
    "reimbursments/SET_EXPORT_LISTS": (state, payload) =>
      combineObjectWithoutCloning(state, { exportLists: payload }),
    "reimbursments/SET_RECENT_ITINERARIES": (state, payload) =>
      combineObjectWithoutCloning(state, { recentItineraries: payload }),
    "reimbursments/SET_ITINERARIES_LISTS": (state, payload) =>
      combineObjectWithoutCloning(state, { itineraries: payload }),
    "recruitments/RESET_ALL": () => reimbursmentsReducer.initialState,
    "RESET_ALL": () => reimbursmentsReducer.initialState
  }
};

export default reimbursmentsReducer;

import moment from "moment-timezone";

import {
  checkEnterpriseId,
  combineObjectWithoutCloning,
} from "@helpers/_functions";

import { makeActionHandler } from "./_reducerUtils";

export const recruitmentsReducer = {
  initialState: {
    availablePosts: [],
    allAvailablePosts: [],
    archivedPosts: [],
    jobPipeline: {},
    filtersPosts: {},
    filterDataStatistics: [],
    filtersRecruits: {
      date: {
        startDate: moment().subtract(3, "month").toDate(),
        endDate: moment().endOf("day").toDate(),
      },
    },
    filterStatistics: {
      entreprise: checkEnterpriseId(),
      date: null,
      offset: null,
      job: null,
      location: null,
      team: null,
    },
    assignedPost: true,
    assignedPostForStat: true,
    jobId: null,
    recruits: [],
    tags: {
      list: [],
      isLoaded: false,
    },
    toClassifyApplications: {
      list: [],
      isLoaded: false,
    },
    cvBankFolder: {
      list: [],
      isLoaded: false,
    },
    pipelineTemplates: {
      list: [],
      isLoaded: false,
    },
    formTemplates: {
      list: [],
      isLoaded: false,
    },
    emailTemplates: {
      list: [],
      isLoaded: false,
    },
    postTeams: {
      list: [],
      isLoaded: false,
    },
    postLocations: {
      list: [],
      isLoaded: false,
    },
    postJobboards: {
      list: [],
      isLoaded: false,
    },
  },
  handlers: {
    "recruitments/SET_AVAILABLE_POSTS": (state, payload) =>
      combineObjectWithoutCloning(state, { availablePosts: payload }),
    "recruitments/SET_ALL_AVAILABLE_POSTS": (state, payload) =>
      combineObjectWithoutCloning(state, { allAvailablePosts: payload }),
    "recruitments/SET_RECRUITS": (state, payload) =>
      combineObjectWithoutCloning(state, { recruits: payload }),
    "recruitments/SET_FILTER_DATA_STATISTICS": (state, payload) =>
      combineObjectWithoutCloning(state, { filterDataStatistics: payload }),
    "recruitments/SET_JOB_PIPELINE": (state, payload) =>
      combineObjectWithoutCloning(state, { jobPipeline: payload }),
    "recruitments/SET_ARCHIVED_POSTS": (state, payload) =>
      combineObjectWithoutCloning(state, { archivedPosts: payload }),
    SET_ASSIGNED_POST: (state, payload) =>
      combineObjectWithoutCloning(state, { assignedPost: payload }),
    SET_ASSIGNED_POST_FOR_STAT: (state, payload) =>
      combineObjectWithoutCloning(state, { assignedPostForStat: payload }),
    SET_FILTERS_AVAILABLE_POSTS: (state, payload) =>
      combineObjectWithoutCloning(state, { filtersPosts: payload }),
    SET_FILTERS_RECRUITS: (state, payload) =>
      combineObjectWithoutCloning(state, { filtersRecruits: payload }),
    "recruitments/SET_FILTERS_STATS": (state, payload) =>
      combineObjectWithoutCloning(state, { filterStatistics: payload }),
    "recruitments/SET_JOB_ID": (state, payload) =>
      combineObjectWithoutCloning(state, { jobId: payload }),
    "recruitments/SET_TAGS": makeActionHandler("tags"),
    "recruitments/SET_TO_CLASSIFY_APPLICATIONS": makeActionHandler(
      "toClassifyApplications",
    ),
    "recruitments/SET_CV_BANK_FOLDER": makeActionHandler("cvBankFolder"),
    "recruitments/SET_PIPELINE_TEMPLATES":
      makeActionHandler("pipelineTemplates"),
    "recruitments/SET_FORM_TEMPLATES": makeActionHandler("formTemplates"),
    "recruitments/SET_EMAIL_TEMPLATES": makeActionHandler("emailTemplates"),
    "recruitments/SET_POST_TEAMS": makeActionHandler("postTeams"),
    "recruitments/SET_POST_LOCATIONS": makeActionHandler("postLocations"),
    "recruitments/SET_POST_JOBBOARDS": makeActionHandler("postJobboards"),

    "recruitments/RESET_ALL": () => recruitmentsReducer.initialState,
    RESET_ALL: () => recruitmentsReducer.initialState,
  },
};

export default recruitmentsReducer;

import { getResponseQuery } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

class EvaluationAlert extends BaseService {
  constructor() {
    super("evaluation-alert");
  }

  upsertOne(evaluationAlertData) {
    return getResponseQuery(
      http.post(`${this.path}/upsert-one`, evaluationAlertData),
    );
  }

  getByAssignedEmployee(assignedEmployee, params = {}) {
    return getResponseQuery(
      http.get(`${this.path}/get-by-assigned-employee/${assignedEmployee}`, {
        params,
      }),
    );
  }

  checkDuplication(assignedEmployee, evaluationDates = [], excludeId = "") {
    return getResponseQuery(
      http.post(`${this.path}/check-duplication`, {
        assignedEmployee,
        evaluationDates,
        excludeId,
      }),
    );
  }
}

export default new EvaluationAlert();

import BaseService from "./base.service";
import http from "../http-common";

class DocumentStorageService extends BaseService {
  constructor() {
    super("document-storage");
  }

  deleteFile({ sourceModel, source }) {
    return http.get(`${this.path}/deleteFile/${sourceModel}/${source}`);
  }
  getByEmployer(EmployerId) {
    const params = {
      userQuerySource: localStorage.getItem("userId"),
      enterpriseId: localStorage.getItem("enterpriseId")
    }
    return http.get(`${this.path}/findByEmployer/${EmployerId}`, { params });
  }
  /**
   * 
   * @param {*} body
   *   { employerId, parentKey, update } 
   */
  updateFolderWithChildsByKey(body) {
    return http.post(`${this.path}/update-folder-with-childs`, body)
  }
  getParentByKey({ key, employerId }) {
    return http.get(`${this.path}/getByKey/${key}/${employerId}`);
  }
  updateById(data) {
    return http.put(`${this.path}/update/${data._id}`, data);
  }
  getByDocumentId(idDocument) {
    return http.get(`${this.path}/findByDocumentId/${idDocument}`);
  }
}

export default new DocumentStorageService();

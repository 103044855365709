import { EmployerGroup, EmployerEmplacement } from "@services/all.services";

export class EmployeUtils {
  static async getEmplacementByEmployer(employeId) {
    let emplacements = [];
    await EmployerEmplacement.getByEmployer(employeId)
      .then((result) => {
        if (!result) {
          throw new Error("Aucune donnée trouvée");
        }
        emplacements = result?.data?.data?.[0]?.Emplacement;
      })
      .catch((err) => console.error("Erreur chargement emplacement: ", err));
    return emplacements;
  }

  static async getGroupAndSubgroup(employeId, groupOptions = { title: false }) {
    let output = [];
    let title = [];
    try {
      const empoyerGroupes = (await EmployerGroup.listByEmployer(employeId))
        .data?.data;
      if (!empoyerGroupes) {
        throw new Error("Aucune donnée trouvée");
      }

      for (const employeGroup of empoyerGroupes) {
        let group = employeGroup.Groupe[0];
        let subGroup = employeGroup.SousGroupe?.[0];
        if (group) {
          const registeredGroup = output.find((g) => g._id === group._id);
          if (registeredGroup && subGroup) {
            title.push(subGroup.title);
            registeredGroup.subgroups.push(subGroup);
          } else {
            let lastGroup = {
              _id: group._id,
              title: group.title,
              subgroups: [],
            };
            output.push(lastGroup);

            title.push(group.title);
            if (subGroup) {
              title.push(subGroup.title);
              lastGroup.subgroups.push(subGroup);
            }
          }
        }
      }

      if (groupOptions.title) {
        return {
          groupAndSubGroups: output,
          groupText: title,
        };
      }
      return output;
    } catch (err) {
      console.error(err);
    }
    return output;
  }
}

import BaseService from "./base.service";
import http from "../http-common";
class EmploiCourseService extends BaseService {
  constructor() {
    super("emploi-course");
  }
  getByEmployer(id, params = {}) {
    return http.get(`${this.path}/getByEmployer/${id}`, { params });
  }
  getById(id) {
    return http.get(`${this.path}/getById/${id}`);
  }
}

export default new EmploiCourseService();

import BaseService from "./base.service";

class ExternalLinkService extends BaseService {
  constructor() {
    super("external-link");
  }

  createExternalLink(dataLink) {
    const enterprise = localStorage.getItem("enterpriseId");
    return this.insert({ ...dataLink, enterprise });
  }
}

export default new ExternalLinkService();

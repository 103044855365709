import { getResponseQuery } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

class DevelopmentPlanService extends BaseService {
  constructor() {
    super("development-plan");
  }

  getDevelopmentPlanList(userId) {
    return getResponseQuery(
      http.get(`${this.path}/get-development-plan-list/${userId}`),
    );
  }
}

export default new DevelopmentPlanService();

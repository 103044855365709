import React, { Component } from "react";
import { SvgShapeDown, SvgShapeUp } from "@routes/IconeSvg";
import "@css/components/input.css";
import "@css/pages/dashboard/global.css";
import InputSimple from "@components/input/Input";

class InputNumber extends Component {
  render = () => {
    return (
      <div
        className={
          "arh-relative arh-w-full " +
          this.props.CustomInput
        }
      >
        <form autoComplete="off">
          <InputSimple
            name={this.props.name}
            id={this.props.id}
            ref={this.props.refs}
            type="text"
            class={`focus:arh-border-greenColor ${this.props.customClassName}`}
            text={this.props.placeholder}
            onChange={this.props.onChange}
            value={this.props.value || ""}
            readOnly={this.props.readOnly}
            data-testid={this.props.testId}
            onClick={this.props.onClick}
            onBlur={this.props.onBlur}
          />
        </form>

        <div className="arh-absolute arh-top-1/2 arh-right-6 -arh-translate-y-1/2">
          <div
            onClick={() => {
              if (!this.props.readOnly) {
                this.props.upClick();
              }
            }}
          >
            {SvgShapeUp()}
          </div>
          <div
            onClick={() => {
              if (!this.props.readOnly) {
                this.props.downClick();
              }
            }}
          >
            {SvgShapeDown()}
          </div>
        </div>
      </div>
    );
  };
}

export default React.memo(InputNumber);

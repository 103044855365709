import { Employer, Users } from "@services/all.services";
import jwt_decode from "jwt-decode";

export const setTokenLocalStorages = (decodedToken) => {
  // export const setTokenLocalStorages = (decodedToken, i18n) => {
  const employerId = decodedToken?.user?._id;
  const language =
    localStorage.getItem("i18nextLng") || decodedToken?.employer?.language;
  if (language !== decodedToken?.employer?.language) {
    Employer.changeStatusOrLang({ language }, employerId).catch((error) =>
      console.log(error)
    );
  }
  // i18n.changeLanguage(language);
  localStorage.setItem("user", JSON.stringify(decodedToken?.user));
  localStorage.setItem("userId", employerId);
  localStorage.setItem("Enterprise", JSON.stringify(decodedToken?.enterprise));
  localStorage.setItem("Employer", JSON.stringify(decodedToken?.employer));
  localStorage.setItem("enterpriseId", decodedToken?.enterprise?._id);
  localStorage.setItem(
    "idAdminEnterprise",
    decodedToken?.enterprise?.accountId
  );
};

const AuthAction = async (userData) => {
  try {
    const loginRes = await Users.login(userData);
    if (loginRes?.data?.success && loginRes?.data?.token) {
      const token = loginRes?.data?.token;
      const decode = jwt_decode(token);
      localStorage.setItem("token", token);
      // localStorage.setItem("auth", JSON.stringify(decode));
      return [true, decode];
    } else {
      if (loginRes?.data?.message) {
        return [false, loginRes.data.message];
      } else {
        return [false, "Erreur de connexion"];
      }
    }
  } catch (err) {
    return [false, err.message];
  }
};

export default AuthAction;

import BaseService from "./base.service";
import http from "../http-common";
import { getResponseQuery } from "../helpers/_functions";

class PosteService extends BaseService {
  constructor() {
    super("poste");
  }
  getById(id) {
    return http.get(`${this.path}/get/${id}`);
  }

  getPosteWithEmploye(enterpriseId) {
    const params = {userId: localStorage.getItem("userId")};

    return getResponseQuery(http.get(`${this.path}/get-poste-with-employe/${enterpriseId}`, {params}));
  }
}

export default new PosteService();

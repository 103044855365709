import BaseService from "./base.service";
import http from "../http-common";
import { checkEnterpriseId, getResponseData } from "../helpers/_functions";
class GroupeService extends BaseService {
  constructor() {
    super("groupe");
  }
  getById(id) {
    return http.get(`${this.path}/get/${id}`);
  }

  getByEnterpriseForSetting (enterpriseId, params = {}) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http
      .get(`${this.path}/list-settings/${enterpriseId}`, { params })
      .then((response) => getResponseData(response));
  }
}

export default new GroupeService();

import { getResponseData, customizeError, checkEnterpriseId } from "@helpers/_functions";
import http from "../http-common";
import BaseService from "./base.service";

class EmploiCertificationService extends BaseService {
  constructor() {
    super("emploi-certification");
  }
  getByEmployer(id, params = {}) {
    return http.get(`${this.path}/getByEmployer/${id}`, { params });
  }
  getById(id) {
    return http.get(`${this.path}/getById/${id}`);
  }
  insert(data) {
    return http
      .post(`${this.path}/create`, data)
      .then((response) => getResponseData(response));
  }
  getCertificationsByEnterprise(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };

    return http.get(`${this.path}/lists/${id}`, { params })
      .then(res => getResponseData(res))
      .catch(err => Promise.reject(customizeError(err)));
  }

  validateListImport(body, params = {}) {
    params.enterpriseId = checkEnterpriseId();
    return http.post(`${this.path}/validate-import`, body, { params })
      .then(res => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  saveImported(body, params = {}) {
    params.Enterprise = checkEnterpriseId();
    return http.post(`${this.path}/save-imported`, body, { params })
      .then(res => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  downloadGabarit() {
    return http.get(`${this.path}/downloadGabarit`, {
      responseType: "blob"
    });
  }

  exportResultImport(body) {
    return http.post(`${this.path}/export-result-import`, body)
      .then(res => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  download(theFileName) {
    return http.get(`${this.path}/download/${theFileName}`, {
      responseType: "blob"
    });
  }
}

export default new EmploiCertificationService();

import moment from "moment-timezone";

import {
  BLANK_PROFILE_PICTURE,
  NOTIFICATION_MSG_EXCEP,
} from "@helpers/_constants";
import { getDateFormat, getDateToRender } from "@helpers/_functions";

export const getContentAndKey = (notification, translation, language) => {
  let { startDate, endDate, createdAt, timezone } = notification;
  if (timezone) {
    startDate = getDateToRender(startDate, timezone);
    endDate = getDateToRender(endDate, timezone);
    createdAt = getDateToRender(createdAt, timezone);
  }

  let key = "",
    content = "",
    option = {},
    msg = notification.msg,
    backgroundColor = "arh-bg-[#FA966B]",
    keyOption = {};
  switch (msg) {
    case "new_vacancy_request":
      key = "Demande de congé";
      content = "new_vacancy_request";
      option = { date: getDateFormat(startDate, endDate, language) };
      backgroundColor = "arh-bg-[#FA966B]";
      break;
    case "new_reimbursment_request":
      key = "Demande de Remboursement";
      content = "new_reimbursment_request";
      option = {
        date: getDateFormat(startDate, endDate, language),
        amount: notification.amount,
      };
      backgroundColor = "arh-bg-[#66C109]";
      break;
    case "new_identification":
      key = "Identification commentaire";
      content = "new_identification";
      option = { date: getDateFormat(startDate, endDate, language) };
      backgroundColor = "arh-bg-[#138EBA]";
      break;
    case "tagged_recruiter":
      // key = "Identification recruiters";
      content = "tagged_recruiter";
      option = {
        jobName: notification.jobName,
        taggerName: notification.taggerName,
        candidateName: notification.candidateName,
      };
      backgroundColor = "arh-bg-[#138EBA]";
      break;
    case "new_file":
      key = "Fichier ajouté";
      content = notification.folderName ? "new_file" : "";
      option = { folderName: notification.folderName };
      backgroundColor = "arh-bg-[#66C109]";
      break;

    case "file_added":
      key = "Fichier ajouté";
      content = notification.folderName ? "file_added" : "";
      option = { folderName: notification.folderName };
      backgroundColor = "arh-bg-[#66C109]";
      break;
    case "your_timetable_updated":
      key = "Horaire modifié";
      content = "your_timetable_updated";
      backgroundColor = "arh-bg-[#FA966B]";
      option = { date: getDateFormat(startDate, endDate, language) };
      break;

    case "your_timetable_deleted":
      key = "Horaire supprimé";
      content = "your_timetable_deleted";
      backgroundColor = "arh-bg-[#FA966B]";
      option = { date: getDateFormat(startDate, endDate, language) };
      break;

    case "vacancy_accepted":
      key = "Congé accepté";
      content = "vacancy_accepted";
      backgroundColor = "arh-bg-[#FA966B]";
      option = { date: getDateFormat(startDate, endDate, language) };
      break;

    case "new_timetable_assigned":
      key = "Nouvel horaire";
      content = "new_timetable_assigned";
      backgroundColor = "arh-bg-[#FA966B]";
      option = { date: getDateFormat(startDate, endDate, language) };
      break;

    case "vacancy_refused":
      key = "Congé refusé";
      content = "vacancy_refused";
      backgroundColor = "arh-bg-[#FA966B]";
      option = { date: getDateFormat(startDate, endDate, language) };
      break;
    case "your_working_hours_updated":
      key = "Modification horaire";
      content = "your_working_hours_updated";
      backgroundColor = "arh-bg-[#FA966B]";
      option = { date: getDateFormat(startDate, endDate, language) };
      break;

    case "news_published":
      key = "Nouvelle publiée";
      content = "news_published";
      option = { date: getDateFormat(createdAt, createdAt, language) };
      backgroundColor = "arh-bg-[#138EBA]";
      break;

    case "new_comment":
      key = "Nouveau commentaire";
      content = "new_comment";
      option = { date: getDateFormat(startDate, endDate, language) };
      backgroundColor = "arh-bg-[#138EBA]";
      break;

    case "certifications_end_date_validity":
      key = "Certification expirée";
      content = "certifications_end_date_validity";
      option = { folderName: notification.folderName };
      backgroundColor = "arh-bg-[#66C109]";
      break;

    case "certifications_alert":
      key = "Alerte certification";
      backgroundColor = "arh-bg-[#66C109]";
      // content="certifications_alert";
      // option = { };
      break;

    case "certifications_updated":
      key = "Certification modifiée";
      backgroundColor = "arh-bg-[#66C109]";
      // content="certifications_updated";
      // option = {};
      break;

    case "certifications_added":
      key = "Certification ajoutée";
      backgroundColor = "arh-bg-[#66C109]";
      // content="certifications_added";
      // option = {};
      break;

    case "formations_added":
      key = "Formation ajoutée";
      backgroundColor = "arh-bg-[#66C109]";
      break;

    case "formations_updated":
      key = "Formation modifiée";
      backgroundColor = "arh-bg-[#66C109]";
      break;

    case "professionnal_career_updated":
      key = "Parcours professionnel modifié";
      backgroundColor = "arh-bg-[#66C109]";
      // content="professionnal_career_updated";
      // option = { }
      break;

    case "new_vacancy_assigned":
      key = "Assignation congé";
      content = "new_vacancy_assigned";
      option = { date: getDateFormat(startDate, endDate, language) };
      break;

    case "your_candidate":
      key = "Au poste";
      content = "your_candidate";
      option = { jobName: notification.jobName };
      break;

    case "assignement_onboarding_task":
      key = "Titre";
      content = notification.taskName;
      break;

    case "onboarding_task_overdue_task":
      key = "Titre";
      content = notification.taskName;
      break;

    case "onboarding_task_warning_2day_before":
      key = "Titre";
      content = notification.taskName;
      break;

    case "onboarding_task_reminding":
      key = "Titre";
      content = notification.taskName;
      break;

    case "task_performed":
      key = "task_performed";
      content = notification.taskName;
      keyOption = {
        performerName: `${notification.legalFirstName} ${notification.legalName}`,
      };
      break;

    case "task_canceled":
      key = "task_canceled";
      content = notification.taskName;
      keyOption = {
        performerName: `${notification.legalFirstName} ${notification.legalName}`,
      };
      break;

    case "evaluation_alert": {
      if (notification.details) {
        key = "Consigne";
        content = notification.details;
        backgroundColor = "arh-bg-[#73C7B7]";
      }
      break;
    }

    case "evaluation_todo_late":
      key = "Date limite";
      content = moment(notification.taskDeadline).format("DD/MM/YYYY");
      backgroundColor = "arh-bg-[#73C7B7]";
      break;

    case "evaluation_todo":
      key = "Date limite";
      backgroundColor = "arh-bg-[#73C7B7]";
      break;

    case "alert_event_unavailability":
      key = "Employé";
      content = moment(notification?.alertDate).format("DD/MM/YYYY");
      break;

    default:
      break;
  }

  return {
    key: translation(key, keyOption),
    content: content
      ? `${content !== "tagged_recruiter" ? ": " : ""}${translation(
        content,
        option,
      )}`
      : " ",
    backgroundColor,
    dataEmployer: notification.dataEmployer,
    dataEnterprise: notification.dataEnterprise,
    path: notification?.path,
  };
};

export const getTitle = (notification = {}, t) => {
  const { msg, dataEnterprise, dataEmployer, legalFirstName, legalName } =
    notification;
  const fullName = dataEmployer?.fullName || `${legalFirstName} ${legalName}`;
  const TITLE_OPTIONS = {
    your_candidate: {
      title: t("Nouvelle Candidature"),
      class: " ",
      parentClass: "",
    },
    onboarding_task_overdue_task: {
      title: t("Tâche en retard"),
      class: " ",
      parentClass: "",
    },
    onboarding_task_warning_2day_before: {
      title: t("Tâche à réaliser"),
      class: " ",
      parentClass: "",
    },
    onboarding_task_reminding: {
      title: t("Tâche à réaliser"),
      class: " ",
      parentClass: "",
    },
    assignement_onboarding_task: {
      title: t("Tâche à réaliser"),
      class: " ",
      parentClass: "",
    },
    task_canceled: {
      title: t("Tâche annulée"),
      class: " ",
      parentClass: "",
    },
    task_performed: {
      title: t("Tâche terminée"),
      class: " ",
      parentClass: "",
    },
    evaluation_todo: {
      title:
        t("Vous avez une nouvelle évaluation à compléter de") + ` ${fullName}`,
      class: " ",
      parentClass: "",
    },
    evaluation_alert: {
      title:
        t("Vous avez un nouveau suivi d'évaluation à compléter concernant") +
        ` ${fullName}`,
      class: " ",
      parentClass: "",
    },
    evaluation_todo_late: {
      title:
        t("Vous avez une évaluation à compléter en retard de") + ` ${fullName}`,
      class: " ",
      parentClass: "",
    },

    alert_event_unavailability: {
      title: t("Alerte Indisponibilité"),
      class: "!arh-w-3 !arh-h-4",
      parentClass:
        "arh-w-[2.875rem] arh-h-[2.875rem] arh-rounded-full arh-overflow-hidden arh-bg-[#A273C7] arh-items-center arh-justify-center",
    },
    // new_reimbursment_request: {
    //   title: `${legalFirstName} ${legalName} ${t("a fait une nouvelle demande de remboursement")}`,
    //   class: " ",
    //   parentClass: ""
    // }
  };

  const name = dataEmployer?.fullName
    ? {
      title: dataEmployer?.fullName,
      class: "arh-object-cover",
      parentClass: "",
    }
    : {
      title: dataEnterprise?.name,
      class: "arh-object-cover",
      parentClass:
        "arh-bg-white arh-border arh-border-solid arh-border-[#D0D2D7] arh-rounded-full",
    };

  return TITLE_OPTIONS[msg] ?? name;
};

export const getImage = (notification) => {
  const { dataEnterprise, dataEmployer } = notification;
  let url = BLANK_PROFILE_PICTURE;
  if (dataEmployer?._id) {
    url = dataEmployer?.profileImage || BLANK_PROFILE_PICTURE;
  } else if (dataEnterprise?._id) {
    if (dataEnterprise?.logo) {
      url = dataEnterprise?.logo;
    } else {
      url = dataEnterprise?.initialName;
    }
  }
  return NOTIFICATION_MSG_EXCEP.includes(notification.msg)
    ? require(`@image/icones/notifications/${notification.icon}`)
    : url;
};

import BaseService from "./base.service";
import http from "../http-common";
import {
  checkUserId,
  checkEnterpriseId,
  getResponseData,
} from "../helpers/_functions";

class EmploiSalaireService extends BaseService {
  constructor() {
    super("emploi-salaire");
  }
  getByEmployer(userId,params={}) {
    userId = checkUserId(userId);
    return http
      .get(`${this.path}/getByEmployer/${userId}`,{params})
      .then((response) => getResponseData(response, true));
  }
  getByUser(enterpriseId) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    return http
      .get(`${this.path}/getByUser/${enterpriseId}`)
      .then((response) => getResponseData(response));
  }
  getByPost(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http.get(`${this.path}/list/${id}`, { params });
  }
  createEmployerUser(data) {
    return http.post(`${this.path}/createEmployerUser`, data);
  }
  checkNumEmploye(numEmploye, idEmployer) {
    const adminUserId = localStorage.getItem("idAdminEnterprise");
    return http.post(`${this.path}/check-num-employe`, {
      numEmploye,
      adminUserId,
      idEmployer,
    });
  }
}

export default new EmploiSalaireService();

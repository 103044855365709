import BaseService from "./base.service";
import http from "../http-common";
class NewsService extends BaseService {
  constructor() {
    super("news-file");
  }
  getByUserId(id) {
    return http.get(`${this.path}/list/${id}`);
  }
  getById(id) {
    return http.get(`${this.path}/get/${id}`);
  }
  uploadNewsFile(data) {
    return http.post(`${this.path}/create-news-file`, data, {});
  }
  uploadNewsThumb(data) {
    return http.post(`${this.path}/create-news-thumb`, data, {});
  }
  deleteThumbById(id) {
    return http.delete(`${this.path}/deleteThumb/${id}`);
  }
  deleteFileById(newsFileIds) {
    return http.post(`${this.path}/deleteNewsFile`, { newsFileIds });
  }
}

export default new NewsService();

import BaseService from "../base.service";
import http from "../../http-common";
import { getResponseQuery } from "../../helpers/_functions";

class EvaluationCriteriaGroup extends BaseService {
  constructor() {
    super("evaluation-criteria-group");
  }

  createEvaluationCriteriaGroup = (data) => getResponseQuery(super.insert(data));

  updateEvaluationCriteriaGroup = (evaluationCriteriaGroup) => 
    getResponseQuery(super.updateById(evaluationCriteriaGroup));
  
  deleteEvaluationCriteriaGroup = (evaluationCriteriaGroupId) => 
    getResponseQuery(super.deleteById(evaluationCriteriaGroupId));

  getGroupEvaluationCriteria = (evaluationCriteriaGroupId) => 
    getResponseQuery(http.get(`${this.path}/get-group-with-evaluationCriterias/${evaluationCriteriaGroupId}`));

  getAllGroupEvaluationCriteria = (enterpriseId) =>
    getResponseQuery(http.get(`${this.path}/get-groups/${enterpriseId}`));
}

export default new EvaluationCriteriaGroup();
import { combineObjectWithoutCloning } from "@helpers/_functions";

export const documentationReducer = {
  initialState: {
    listFolder: [],
    listEmployeFolder: [],
    organigram: null
  },
  handlers: {
    "documentation/SET_LIST_FOLDER": (state, payload) =>
      combineObjectWithoutCloning(state, { listFolder: payload }),
    "documentation/SET_LIST_EMPLOYE_FOLDER": (state, payload) =>
      combineObjectWithoutCloning(state, { listEmployeFolder: payload }),
    "documentation/SET_ORGANIGRAM": (state, payload) =>
      combineObjectWithoutCloning(state, { organigram: payload }),
    "RESET_ALL": () => documentationReducer.initialState
  }
};

export default documentationReducer;

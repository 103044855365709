import moment from "moment";

import { getResponseQuery } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

class EvaluationRequest extends BaseService {
  constructor() {
    super("evaluation-requests");
  }

  upsertOne(requestBody) {
    return getResponseQuery(http.post(`${this.path}/upsert`, requestBody));
  }

  getByEnterprise(params = {}) {
    const enterpriseId = localStorage.getItem("enterpriseId");

    return getResponseQuery(
      http.get(`${this.path}/get-by-enterprise/${enterpriseId}`, {
        params,
      }),
    );
  }

  getDevelopmentPlanList(userId) {
    return getResponseQuery(
      http.get(`${this.path}/get-development-plan-list/${userId}`),
    );
  }

  getOverview(userId) {
    const params = {
      actualDate: moment().format("YYYY-MM-DD"),
    };

    return getResponseQuery(
      http.get(`${this.path}/get-overview/${userId}`, {
        params,
      }),
    );
  }

  getByAssignedEmployee(assignedEmployee, params = {}) {
    return getResponseQuery(
      http.get(`${this.path}/get-by-assigned-employee/${assignedEmployee}`, {
        params,
      }),
    );
  }

  /**
   *
   * @param {Object} options={} - options
   * @param {string} options.assignedEmployee - assignedEmployee
   * @param {string[]} options.limitDates - limitDates
   * @param {string} options.evaluationType - evaluationType
   * @param {string=} options.excludeId - excludeId
   */
  checkDuplication(options) {
    return getResponseQuery(
      http.post(`${this.path}/check-duplication`, options),
    );
  }
}

export default new EvaluationRequest();

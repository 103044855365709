import React from "react";

/**
 **
 * @typedef {Object} Props
 * @property {boolean=} invalid - Whether the textarea is in an invalid state.
 * @property {string} value - The value of the textarea.
 * @property {string} name - The name of the textarea input.
 * @property {string} placeholder - The placeholder text for the textarea.
 * @property {string} label - The label text for the textarea.
 * @property {string=} additionnalClas - Additional CSS classes for the textarea.
 * @property {boolean=} disabled - Whether the textarea is disabled.
 * @property {(event: any)=>void=} onChange - Function to handle change events.
 * @property {(event: any)=>void=} onBlur - Function to handle blur events.
 *
 * TextArea component.
 * @param {Props} props - The component's props.
 * @returns {JSX.Element} - The rendered JSX element.
 */
export default function TextArea(props) {
  const {
    invalid = false,
    value = "",
    name = "",
    onChange = () => {},
    onBlur = () => {},
    placeholder = "",
    label = "",
    additionnalClas = "",
    disabled = false,
  } = props;

  const className = {
    normal:
      "arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
    invalid:
      "arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput",
  };

  const trimValue = (event) => {
    if(event.target.value.trim() === '') {
      event.target.value = '';
    } 
  }

  const onChangeWithTrim = (event) => {
    trimValue(event);
    onChange(event)
  }

  const onBlurWithTrim = (event) => {
    trimValue(event);
    onBlur(event);
  }

  return (
    <div className="arh-flex arh-flex-col arh-space-y-1">
      {label && (
        <label
          className={`arh-mb-0 arh-w-full arh-text-left arh-font-poppins  arh-text-xs arh-font-medium ${
            invalid
              ? "arh-text-invalidColorTextInput "
              : disabled
              ? "arh-text-greyDisabledText "
              : "arh-text-darkColor "
          }`}
        >
          {label}
        </label>
      )}
      <textarea
        name={name}
        onChange={onChangeWithTrim}
        onBlur={onBlurWithTrim}
        placeholder={placeholder}
        className={`arh-min-h-[80px] arh-w-full arh-rounded arh-border arh-border-solid arh-px-6 arh-py-3 arh-font-poppins arh-text-xs arh-font-normal placeholder:arh-text-xs disabled:arh-cursor-default disabled:arh-border-greyDisabledBorder disabled:arh-bg-greyDisabled disabled:arh-text-greyDisabledText disabled:placeholder:arh-text-greyDisabledText  ${additionnalClas}
        ${invalid ? className["invalid"] : className["normal"]}`}
        value={value}
        disabled={disabled}
      />
    </div>
  );
}

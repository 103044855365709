import React, { Component } from "react";
// import Shape from '../../assets/image/icones/Shape.png';
import { SvgShapeDropdown, SvgCalendar } from "@routes/IconeSvg";
import CoolCalendar from "@assets/image/icones/calendarFilter.svg";
import "@css/components/input.css";
import "@css/pages/dashboard/global.css";
import ResetFilter from "@components/tailwindComponents/ResetFilter";

export default class InputSelect extends Component {
  onClickReset = () => {
    if (!this.props.readOnly) this.props.onResetFilter();
  };

  render = () => {
    const { onChange = () => {} } = this.props;
    return (
      <>
        <div
          className="input_with_loupe position_relative"
          onClick={this.props.onClick}
          onBlur={this.props.onBlur}
        >
          {this.props.coolCalendar ? (
            <img
              src={CoolCalendar}
              alt="cool calendar"
              className={this.props.classNameCoolCalendar}
            />
          ) : (
            ""
          )}
          <input
            type={this.props.type}
            onChange={onChange}
            className={`arh-font-poppins arh-font-normal focus:arh-outline-none  ${
              !this.props.invalid
                ? "placeholder:!arh-text-[#8B8F91]"
                : "placeholder:!arh-text-invalidColorTextInput !arh-border-invalidColorTextInput arh-bg-invalidBackgroundInput"
            } !arh-text-xs ${this.props.className} ${
              this.props.readOnly &&
              "!arh-text-[#9D9D9D] arh-bg-[#fafafa] arh-pointer-events-none"
            }`}
            value={this.props.value || ""}
            ref={this.props.refs}
            name={this.props.name}
            id={this.props.id}
            placeholder={this.props.placeholder}
            onBlur={this.props.onBlur}
            // readOnly={this.props.readOnly}
            style={{
              height: this.props.height,
              padding: this.props.padding,
              marginBottom: this.props.marginBottom,
              marginTop: this.props.marginTop
            }}
            data-id={this.props.dataId}
            data-testid={this.props.testId}
          />
          {/* <img alt="loupe" className={"icones_loup_input icones_shape_date position_absolute custom_image mt_5_custom " + this.props.classLoupe} style={{right:this.props.right,top:this.props.top}} src={Shape} /> */}
          {!this.props.coolCalendar && !this.props.IconCalendar && (
            <div className={this.props.fadeIn ? "shape_Open" : "shapeNo_open"}>
              {SvgShapeDropdown()}
            </div>
          )}
          {this.props.IconCalendar && (
            <>
              <div className="border_dateRange"></div>
              <div className={this.props.classCalendar}>{SvgCalendar()}</div>
            </>
          )}
          {/* <img alt="loupe" className={"icones_loup_input icones_shape_date position_absolute custom_image mt_5_custom " + this.props.classLoupe} style={{right:this.props.right,top:this.props.top}} src={Shape} /> */}
        </div>
        {this.props.showResetFilter && this.props.value && (
          <ResetFilter
            onClick={this.onClickReset}
            readOnly={this.props.readOnly}
          />
        )}
      </>
    );
  };
}

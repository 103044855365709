import React from 'react';
import ThreeDotsLoader from "@components/loader/Loading";

const BtnLoading = () => {
  return (
    <div className="arh-w-full arh-h-[18.85px] arh-flex arh-justify-center arh-items-center">
      <ThreeDotsLoader color="#FFFFFF" height="14" width="60" />
    </div>
  )
}

export default BtnLoading;
  import { checkEnterpriseId, getResponseData } from "../helpers/_functions";
import http from "../http-common";

export default class BaseService {
  constructor(path) {
    this.path = path;
  }

  insert(data, params = {}) {
    return http.post(`${this.path}/create`, data, { params });
  }

  getAll() {
    return http.get(`${this.path}/list`);
  }

  getByEnterprise(enterpriseId, params = {}) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http
      .get(`${this.path}/list/${enterpriseId}`, { params })
      .then((response) => getResponseData(response));
  }

  getByIdAdminEnterprise(idAdminEnterprise, idUserConnected) {
    return http
      .get(`${this.path}/list/${idAdminEnterprise}`, {
        params: { userId: idUserConnected }
      })
      .then((response) => getResponseData(response));
  }

  getById(id) {
    return http
      .get(`${this.path}/getById/${id}`)
      .then((response) => getResponseData(response));
  }

  updateById(item, params = {}) {
    return http.put(`${this.path}/update/${item._id}`, item, { params });
  }

  updateOrder(items) {
    return http.post(`${this.path}/update-order`, items);
  }

  deleteById(id, params = {}) {
    if (id) return http.delete(`${this.path}/delete/${id}`, { params });
  }

  getServerCurrentDate() {
    return http
      .get(`/new_date`)
      .then((response) => response.data.currentDate)
      .catch((err) => console.error(err));
  }

  sendEmail(params) {
    //params (object): email, subject, content, from, attachements, returnPath
    return http.post(`/send_email`, params);
  }

  actualizeEmploye(_id) {
    return http.put(`${this.path}/actualize/${_id}`);
  }
}

import BaseService from "./base.service";
import http from "../http-common";

class HoraireService extends BaseService {
  constructor() {
    super("horaire");
  }
  getByEmployer(id) {
    return http.get(`${this.path}/getByEmployer/${id}`);
  }

  getOneDayByEmployer(params) {
    const { employerId, date } = params;
    return http.get(`${this.path}/getOneDayByEmployer/${employerId}/${date}`);
  }

  getOneWeekByEmployer(params) {
    const { employerId, date } = params;
    return http.get(`${this.path}/getOneWeekByEmployer/${employerId}/${date}`);
  }

  getOneMonthForCalendarByEmployer(params = {}) {
    return http.get(`${this.path}/getOneMonthForCalendarByEmployer`, {
      params,
    });
  }

  // getHoraryWithoutLunchOneMonthForCalendarByEmployer(params = {}) {
  //   return http.get(
  //     `${this.path}/getHoraryWithoutLunchOneMonthForCalendarByEmployer`,
  //     { params }
  //   );
  // }

  getHoraryBetweenTwoDate(params) {
    const { employerId, startDateParam, endDateParam } = params;
    return http.get(
      `${this.path}/getHoraryBetweenTwoDate/${employerId}/${startDateParam}/${endDateParam}`
    );
  }

  getOneMonthHoraryForCalendarByEnteprise(value, params = {}) {
    const { enterpriseId, date, timezone } = value;
    params = {
      ...params,
      timezone: timezone,
      userId: localStorage.getItem("userId")
    }
    return http.get(
      `${this.path}/getOneMonthHoraryForCalendarByEnteprise/${enterpriseId}/${date}`,
      { params }
    );
  }

  getOneWeekHoraryForCalendarByEnteprise(value, params = {}) {
    const { enterpriseId, date } = value;
    return http.get(
      `${this.path}/getOneWeekHoraryForCalendarByEnteprise/${enterpriseId}/${date}`,
      { params }
    );
  }

  getOneMonthHoraryWithoutLunchForCalendarByEnteprise(value, params = {}) {
    const { enterpriseId, date } = value;
    return http.get(
      `${this.path}/getOneMonthHoraryWithoutLunchForCalendarByEnteprise/${enterpriseId}/${date}`,
      { params }
    );
  }
  // getOneMonthHoraryWithoutLunchForWeekDetails(value, params = {}) {
  //   const { enterpriseId, date } = value;
  //   return http.get(
  //     `${this.path}/getOneMonthHoraryWithoutLunchForWeekDetails/${enterpriseId}/${date}`,
  //     { params }
  //   );
  // }
  getOneWeekForCalendarByEmployer(params) {
    const { employerId, date } = params;
    return http.get(
      `${this.path}/getOneWeekForCalendarByEmployer/${employerId}/${date}`
    );
  }

  getOneMonthByEmployer(params) {
    const { employerId, date } = params;
    return http.get(`${this.path}/getOneMonthByEmployer/${employerId}/${date}`);
  }

  getOneMonthByEnterprise(params) {
    const { enterpriseId, date } = params;
    return http.get(
      `${this.path}/getOneMonthByEnterpriseId/${enterpriseId}/${date}`
    );
  }

  getOneWeekByEnterpriseId(params) {
    const { enterpriseId, date } = params;
    return http.get(
      `${this.path}/getOneWeekByEnterpriseId/${enterpriseId}/${date}`
    );
  }

  deleteMultiple = (params) => {
    return http.post(`${this.path}/deleteMultiple`, { params });
  };

  updateMultiple = (data) => {
    return http.post(`${this.path}/updateMultiple`, data);
  };
}

export default new HoraireService();

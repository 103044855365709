import React from "react";

import { SvgResetFilter } from "@routes/IconeSvg";

/**
 *
 * @param {object} props
 * @param {React.MouseEventHandler<HTMLDivElement>} props.onClick
 * @param {boolean=} props.readOnly
 * @param {string=} props.customClassName
 * @returns
 */
const ResetFilter = ({ onClick, readOnly, customClassName }) => {
  return (
    <div
      className={`arh-absolute arh-top-1/2 arh-left-1.5 arh-flex -arh-translate-y-1/2 arh-cursor-pointer arh-items-center arh-justify-center arh-rounded-full arh-p-2 hover:arh-bg-gray-200 active:arh-bg-greyActive ${
        readOnly && "!arh-cursor-auto"
      } ${customClassName}`}
      onClick={onClick}
    >
      <SvgResetFilter />
    </div>
  );
};

export default ResetFilter;

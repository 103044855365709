import http from "../../http-common";
import {
  customizeError,
  checkEnterpriseId,
  getResponseData,
} from "@helpers/_functions";

export default class BaseOnboardingService {
  constructor(path) {
    this.path = path;
  }

  insert(data) {
    return http
      .post(`${this.path}/`, data)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getByEnterprise(enterpriseId) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    return http
      .get(`${this.path}/enterprise/${enterpriseId}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  getByEmploye(employeId, query = '') {
    return http
      .get(`${this.path}/employer/${employeId}${query}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  findById(id) {
    return http
      .get(`${this.path}/${id}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  updateById(item) {
    return http.put(`${this.path}/${item._id}`, item);
  }

  deleteById(id) {
    return http
      .delete(`${this.path}/${id}`)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  filterBy(queries, userId = null) {
    const query = userId ? `?userId=${userId}` : "";
    return http
      .post(`${this.path}/filter${query}`, queries)
      .then((response) => getResponseData(response))
      .catch((err) => Promise.reject(customizeError(err)));
  }
}

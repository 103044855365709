import { makeActionHandler } from "./_reducerUtils";

const workingHoursReducer = {
  initialState: {
    projects: {
      list: [],
      listInRepartition: [],
      isLoaded: false,
      filter: {},
      isFiltered: false,
      isFiltering: false,
      firstFilter: true
    },
    formationHours: {
      list: [],
      totalHours: 0,
      isLoaded: false,
      filter: {},
      isFiltered: false
    },
    timeclock: {
      list: [],
      isLoaded: false
    },
    employeHour: {
      list: [],
      isLoaded: false
    },
    lockedDay: {
      list: [],
      isLoaded: false
    }
  },
  handlers: {
    "workingHours/SET_PROJECTS": makeActionHandler("projects"),
    "workingHours/SET_FORMATION_HOURS": makeActionHandler("formationHours"),
    "workingHours/SET_TIMECLOCK": makeActionHandler("timeclock"),
    "workingHours/SET_EMPLOYER_HOUR": makeActionHandler("employeHour"),
    "workingHours/SET_LOCKED_DAY": makeActionHandler("lockedDay"),

    "RESET_ALL": () => workingHoursReducer.initialState
  }
};

export default workingHoursReducer;

import moment from "moment";
import http from "../http-common";
import BaseService from "./base.service";

class AnniversaryService extends BaseService {
  constructor() {
    super("anniversary");
  }

  async getHiringByAdminId(params = {}) {
    const adminId = localStorage.getItem("idAdminEnterprise");
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    const response = await http.get(
      `${this.path}/getHiringByAdminId/${adminId}`,
      { params }
    );
    if (Array.isArray(response?.data?.data?.[0]?.docs)) {
      let data = response?.data?.data?.[0];
      data.docs = data.docs.map((doc) => {
        const hiringDate = (doc.defaultDateHiring && doc.seniorityDate) ? doc.seniorityDate : doc.hiringDate;
        const dateActuelle = moment();
        const anneeActuelle = dateActuelle.year();
        const prochainAnniversaire = moment(hiringDate).set(
          "year",
          anneeActuelle
        );
        if (dateActuelle.isAfter(prochainAnniversaire)) {
          prochainAnniversaire.add(1, "year");
        }
        doc.anniversaryDate = prochainAnniversaire.format("DD/MM/YYYY");
        doc.age = dateActuelle.diff(moment(hiringDate), "years",true);
        return doc;
      });
      data.docs = data.docs.sort((a, b) => moment(a.anniversaryDate, "DD/MM/YYYY").diff(moment(b.anniversaryDate, "DD/MM/YYYY")));
      return data;
    } else {
      return {
        count: 0,
        docs: []
      };
    }
  }

  async getBirthByAdminId(params = {}) {
    const adminId = localStorage.getItem("idAdminEnterprise");
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    const response = await http.get(
      `${this.path}/getBirthByAdminId/${adminId}`,
      { params }
    );
    if (Array.isArray(response?.data?.data?.[0]?.docs)) {
      let data = response?.data?.data?.[0];
      return data;
    } else {
      return {
        count: 0,
        docs: []
      };
    }
  }

  async addNote(body) {
    const response = await http.post(`${this.path}/createNote`, body);
    return response?.data?.data;
  }

  async deleteNote(noteId) {
    const response = await http.delete(`${this.path}/deleteOneNote/${noteId}`);
    return response?.data?.data;
  }
}

export const Anniversary = new AnniversaryService();

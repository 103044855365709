import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { ListboxSimple } from "./";

function generateYearOptions() {
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 9;
  const maxYear = currentYear //+ 1;

  const years = [];

  for (let i = maxYear; i >= minYear; i--) {
    years.push({
      _id: i,
      name: i.toString(),
    });
  }

  return years;
}

export default function DropdownYear({
  control,
  rules = {},
  name="year",
  setSelectedYearEvolution
}) {
  const yearsOption = useMemo(() => generateYearOptions(), []);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={yearsOption[0]}
      render={({ field: { onChange, value, name } }) => (
        <ListboxSimple
          name={name}
          // placeholder={currentYear.toString()}
          noRadio
          optionList={yearsOption}
          selectedValue={value}
          setSelectedValue={(val)=> {
            onChange(val)
            if(setSelectedYearEvolution){
              setSelectedYearEvolution(val.name)
            }
          }}
          widthDrop="!arh-w-full arh-px-0 arh-mt-2.5"
          customClass="arh-ml-0"
          className="arh-justify-center"
          customItemWidth="arh-min-w-24"
        />
      )}
    />
  );
}

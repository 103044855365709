import BaseOnboardingService from "./baseOnboarding.service";
import http from "../../http-common";

class EventUnaivalabilityService extends BaseOnboardingService {
  constructor() {
    super("event-unaivalability");
  }
  update(id,item) {
    return http.put(`${this.path}/${id}`, item);
  }
}

export default new EventUnaivalabilityService();

import BaseService from "./base.service";
import http from "../http-common";
class NotificationService extends BaseService {
  constructor() {
    super("notification");
  }

  getByType = (userId, type, params = {}) =>
    new Promise((resolve, reject) => {
      http
        .get(`${this.path}/get-by-type/${userId}/${type}`, { params })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  markAsRead = (body) =>
    new Promise((resolve, reject) => {
      http
        .post(`${this.path}/mark-as-read`, body)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  updateManyByOwnerAndType = (ownerId, type, body) => {
    return http.post(`${this.path}/updateManyByOwnerAndType/${ownerId}/${type}`, body);
  };

  notificationEnterprise = (data) => {
    return http.post(`${this.path}/notification-enterprise`, data);
  };
  notificationByAccess = (data) => {
    return http.post(`${this.path}/notification-by-access`, data);
  };

  notificationEmployer = (data) => {
    return http.post(`${this.path}/notification-employer`, data);
  };

  createNotification = (data) => {
    return http.post(`${this.path}/create`, data);
  };

  notificationMultipleUsers = (data) => {
    return http.post(`${this.path}/notification-multiple-users`, data);
  };

  deleteNotification = (trigger) => {
    return http.delete(`${this.path}/delete/${trigger.name}/${trigger.data}`);
  };

  getNotificationsUnread =(id)=>{
    return http.get(`${this.path}/count-notification/${id}`);
  }
}

export default new NotificationService();

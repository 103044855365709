import BaseService from "./base.service";
import http from "../http-common";
class NotificationPushService extends BaseService {
  constructor() {
    super("notification-push");
  }
  push = (data) =>
  new Promise((resolve, reject) => {
    http
      .post(`${this.path}/`, { data })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default new NotificationPushService();
import { getResponseQuery as _q } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

// const _q = getResponseQuery;

class OnboardingTask extends BaseService {
  constructor() {
    super("onboarding-task");
  }

  createTask = (data) => _q(super.insert(data));

  updateTask = (taskId, task) => _q(http.put(`${this.path}/update/${taskId}`, task));
  
  deleteTask = (taskId) => _q(super.deleteById(taskId));
}

export default new OnboardingTask();
import BaseService from "./base.service";
import http from "../http-common";
class EmployerEmplacementService extends BaseService {
  constructor() {
    super("employer-emplacement");
  }
  insert(data) {
    return http.post(`${this.path}/create`, data);
  }
  getByEmployer(id) {
    return http.get(`${this.path}/listByEmployer/${id}`);
  }
  deleteByEmployerId(id) {
    return http.delete(`${this.path}/deleteByEmployer/${id}`);
  }
  deleteByEmplacementId(id) {
    return http.delete(`${this.path}/deleteByEmplacement/${id}`);
  }
}

export default new EmployerEmplacementService();

import { getResponseQuery as _q } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

//const _q = getResponseQuery;

class OnboardingEmployeProtocol extends BaseService {
  constructor() {
    super("onboarding-employe-protocol");
  }

  createProtocol = (data) => _q(super.insert(data));

  updateProtocol = (protocol) => _q(super.updateById(protocol));

  deleteProtocol = (protocolId) => _q(super.deleteById(protocolId));

  getProtocolsWithTasks = (enterpriseId) =>
    _q(http.get(`${this.path}/get-protocols-with-tasks/${enterpriseId}`));

  getProtocolWithTasksByEmploye = (employeId) =>
    _q(http.get(`${this.path}/get-protocol-with-tasks-by-employe/${employeId}`));
}

export default new OnboardingEmployeProtocol();

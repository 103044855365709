import BaseDocumentationService from "./baseDocumentation.service";
import http from "../../http-common";

class DocumentationService extends BaseDocumentationService {
  constructor() {
    super("documentation");
  }

  getEnterpriseChart(id) {
    return http.get(`${this.path}/flowchart/${id}`);
  }
}

export default new DocumentationService();

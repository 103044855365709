import { combineObjectWithoutCloning } from "@helpers/_functions";

export const abstractData = {
  initialState: {
    nameEmployers: localStorage.getItem("Dossier_Employé_Employé_name") || "",
    selectedFolderKey: null,
    socket: {},
    socketBackOffice: {},
    currentTab: null,
  },
  handlers: {
    "NAME_EMPLOYER": (state, payload) => combineObjectWithoutCloning(state, { nameEmployers: payload }),
    "SET_SELECTED_FOLDER": (state, payload) => combineObjectWithoutCloning(state, { selectedFolderKey: payload }),
    "SET_SOCKET": (state, payload) => combineObjectWithoutCloning(state, { socket: payload }),
    "SET_SOCKET_BACK_OFFICE": (state, payload) => combineObjectWithoutCloning(state, { socketBackOffice: payload }),
    "SET_CURRENT_TAB": (state, payload) => combineObjectWithoutCloning(state, { currentTab: payload }),
    "RESET_ALL": () => abstractData.initialState
  }
};

export default abstractData;
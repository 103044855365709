import { combineObjectWithoutCloning } from "@helpers/_functions";

function isObject(value) {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
}

export function makeActionHandler(stateKey) {
  return (state, payload = {}) => {
    if (!isObject(payload)) {
      console.error("payload should be an object", { payload });
      return state;
    }

    return combineObjectWithoutCloning(state, {
      [stateKey]: combineObjectWithoutCloning(state[stateKey] || {}, payload)
    });
  }
}
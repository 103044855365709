/*eslint array-callback-return:off*/
import { uniqBy } from "lodash";
import moment from "moment-timezone";
import addNotification from "react-push-notification";

import tickAudio from "@assets/sound/unsure-566.mp3";

import {
  NOTIFICATION_NEWS,
  NOTIFICATION_CONGE,
  NOTIFICATION_NEWS_COMMENT,
  NOTIFICATION_WORKING_HOUR,
  NOTIFICATION_DOCUMENT_STORAGE,
  NOTIFICATION_EMPLOIE_CERTIFICATION,
  NOTIFICATION_EMPLOIE_COURSE,
  NOTIFICATION_HORAIRE,
  NOTIFICATION_TYPE_ENTERPRISE,
  NOTIFICATION_TYPE_USER,
  NOTIFICATION_ONBOARDING_EMPLOYE_TASK,
  CONGE_STATUS_APPROUVED,
  MODEL_CANDIDATE,
  BLANK_PROFILE_PICTURE,
  LOGO_ALTEE,
  NOTIFICATION_EMPLOIE_FORMATION,
  MODEL_REIMBURSEMENT,
  maFicheEmploye
} from "@helpers/_constants";
import { getContentAndKey } from "@helpers/notificationRenderHelpers";
import {
  Notification as NotificationService,
  NotificationPushService,
} from "@services/all.services";

import {
  getEmployerPersonnalInfo,
  getParentFolderName,
  getEmployerId,
} from "./subUtils/notification.helper";

const PATH_NEWS = "/admin/employe-nouvelles";
export const PATH_NEWS_COMMENT = "/admin/entreprise-nouvelles";
const PATH_NEWS_TAG = "/admin/employe-nouvelles";
const PATH_CONGE_NEW = "/admin/planification-des-horaires";
const PATH_CONGE_APPROVAL = "/admin/mon-horaire";
const PATH_CONGE_ASSIGNMENT = "/admin/mon-horaire";
const PATH_WORKING_HOUR_UPDATE = "/admin/suivi-des-heures";
const PATH_SCHEDULE = "/admin/mon-horaire";
const PATH_DETAIL_EMPLOYER = "/admin/dossiers-employes/";
const PATH_MA_FICHE = "/admin/" + maFicheEmploye;
const PATH_RECRUTEMENTS_PEPILINE = "/admin/recruitments";
const ONGLET_NEWS_EMPLOIE = "DNews";
const TAB_MY_HOURS_VACANCY_ABSCENCES = "DWolidayAndAbsences";
const TAB_FICHE_EMPLOYE_DOCUMENT = "DDocument";
const TAB_JOB_SALARY_HOLIDAYS = "DEmploisalary";
//const TAB_FICHE_FILEMANAGER = "DDocuments";
const TAB_VACANCY_ABSENCE = "vacancyAbsence";
const ONGLET_NEWS_ENTERPRISE = "news";
const TAB_HOURS_WORKED = "DWorkingHours";
// const PERMISSION_NAME_LIST_EMPLOYEE = "listBuild";
const TAB_LIST_DOCUMENT_NOTE = "DListDocumentNote";
const TAB_FICHE_CERT_FORMATION = "DCertFormation";

class NotificationUtilClass {
  constructor(socket) {
    this.socket = socket;
  }

  news = async (newData) => {
    try {
      let dataNotification = {
        icon: "news.svg",
        // msg: "Une nouvelle vient d’être publiée",
        msg: "news_published",
        trigger: {
          name: NOTIFICATION_NEWS,
          data: newData?._id,
        },
        path: PATH_NEWS + (newData?._id ? "?newsId=" + newData._id : ""),
        tab: ONGLET_NEWS_EMPLOIE,
        startDate: newData?.createdAt,
        endDate: newData?.createdAt,
      };
      if (newData?.postBy) {
        dataNotification.userId = localStorage.getItem("userId");
      }
      // return this.notifyByAccess({ dataNotification,filters });
      return this.notifyAllUsers({
        dataNotification,
        filters: { newsId: newData?._id },
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  // + news.createdAt on attribute
  newsComment = async (data) => {
    try {
      const { legalFirstName, legalName } =
        await getEmployerPersonnalInfo(data);

      const dataNotification = {
        icon: "news.svg",
        msg: "new_comment",
        legalFirstName: legalFirstName,
        legalName: legalName,
        startDate: data?.NewsCreatedAt,
        endDate: data?.NewsCreatedAt,
        trigger: {
          name: NOTIFICATION_NEWS_COMMENT,
          data: data._id,
        },
        path:
          PATH_NEWS_COMMENT +
          (data?._id ? "?newsId=" + data.News + "&commentId=" + data._id : ""),
        tab: ONGLET_NEWS_ENTERPRISE,
        userId:
          data?.Employer?.[0]?._id ||
          data?.Employer?._id ||
          data?.Employer ||
          null,
      };
      return this.notifyEnterprise({
        dataNotification,
        filters: { newsId: data.News },
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  // notification for tagged employers
  newsTagged = async (data, employerIds) => {
    try {
      const dataNotification = {
        icon: "news.svg",
        // msg: `Vous avez été identifier sur un commentaire de la nouvelle du ${toFrenchFullDate(
        //   data.news?.createdAt
        // )}`,
        msg: "new_identification",
        startDate: data?.createdAt,
        endDate: data?.createdAt,
        trigger: {
          name: NOTIFICATION_NEWS_COMMENT,
          data: data._id,
        },
        userId: localStorage.getItem("userId"),
        path:
          PATH_NEWS_TAG +
          (data?._id ? "?newsId=" + data.News + "&commentId=" + data._id : ""),
        tab: ONGLET_NEWS_EMPLOIE,
      };
      if (Array.isArray(employerIds)) {
        employerIds.forEach((employerId) => {
          return this.notifyEmployer({
            data: { employerId },
            dataNotification,
          });
        });
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  //Notification for RecruitersNote
  recruitersTagged = (data, employerId) => {
    try {
      const connectedUser = JSON.parse(localStorage.getItem("user"));
      const dataNotification = {
        icon: "newCandidate.svg",
        msg: "tagged_recruiter",
        trigger: {
          name: MODEL_CANDIDATE,
          data: data.id,
        },
        jobId: data.idPost?._id,
        jobName: data.idPost?.title,
        taggerName: connectedUser.username,
        candidateName: `${data.firstname} ${data.name}`,
        userId: connectedUser._id,
        path: `${PATH_RECRUTEMENTS_PEPILINE}?jobId=${data.idPost?._id}&candidate=${data.id}`,
        tab: "availablePostes",
      };
      // if (Array.isArray(employerId)) {
      //   employerId.forEach((employerId) => {
      return this.notifyEmployer({
        data: { employerId },
        dataNotification,
      });
      // });
      // }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  conge = (data, subData = {}, notifyEnterprise = false) => {
    try {
      const dataNotification = {
        icon: "HoursCorrection.svg",
        trigger: {
          name: NOTIFICATION_CONGE,
          data: data._id,
        },
        userId: data?.Employer?.[0]?._id || data?.Employer?._id || null,
        ...subData,
      };
      if (notifyEnterprise) {
        return this.notifyEnterprise({
          dataNotification,
          filters: { employerId: data?.Employer?.[0]?._id },
        });
      } else {
        return this.notifyEmployer({ data, dataNotification });
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  congeNew = async (data) => {
    try {
      const { legalFirstName, legalName } =
        await getEmployerPersonnalInfo(data);
      return this.conge(
        data,
        {
          // msg: `${legalFirstName} ${legalName} a fait une nouvelle demande de congé ${congeDateMsg(
          //   data
          // )}`,
          msg: "new_vacancy_request",
          legalFirstName: legalFirstName,
          legalName: legalName,
          userId:
          data?.Employer?.[0]?._id ||
          data?.Employer?._id ||
          data?.Employer ||
          null,
          startDate: data.start,
          endDate: data.end,
          path: PATH_CONGE_NEW + (data?._id ? "?id=" + data._id : ""),
          tab: TAB_VACANCY_ABSENCE,
          timezone: data.timezone,
        },
        true,
      );
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  newReimbursementRequest = async (data) => {
    try {
      const { legalFirstName, legalName } =
        await getEmployerPersonnalInfo(data);
      const dataNotification = {
        icon: "HoursCorrection.svg",
        trigger: {
          name: MODEL_REIMBURSEMENT,
          data: data._id,
        },
        userId: data.Employer?._id || null,
        msg: "new_reimbursment_request",
        legalFirstName,
        legalName,
        startDate: data.date,
        endDate: data.date,
        amount: `${data.devise} ${data.amount}`,
        path: `/admin/enterprise-expenses-notes?id=${data._id}`,
        tab: "expensesNotes",
        timezone: data.timezone,
      };
      return this.notifyEnterprise({
        dataNotification,
        filters: { employerId: data?.Employer?._id },
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  congeApproval = (data) => {
    try {
      return this.conge(data, {
        // msg: `La demande de congé ${congeDateMsg(data)} a été ${statusConge(
        //   data
        // )}`,
        msg:
          data.status === CONGE_STATUS_APPROUVED
            ? "vacancy_accepted"
            : "vacancy_refused",
        startDate: data.start,
        endDate: data.end,
        path: PATH_CONGE_APPROVAL + (data?._id ? "?id=" + data._id : ""),
        tab: TAB_MY_HOURS_VACANCY_ABSCENCES,
        timezone: data.timezone,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  congeAssignment = (data) => {
    data = this.getHourWithTimezone(data);
    try {
      return this.conge(data, {
        // msg: `Un nouveau congé vous a été attribué ${congeDateMsg(data)}`,
        msg: "new_vacancy_assigned",
        startDate: data.start,
        endDate: data.end,
        path: PATH_CONGE_ASSIGNMENT + (data?._id ? "?id=" + data._id : ""),
        tab: TAB_MY_HOURS_VACANCY_ABSCENCES,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  workingHour = (data, subData = {}) => {
    try {
      const dataNotification = {
        icon: "HoursCorrection.svg",
        trigger: {
          name: NOTIFICATION_WORKING_HOUR,
          data: data._id,
        },
        ...subData,
      };
      return this.notifyEmployer({
        data,
        dataNotification,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  workingHourUpdate = (data) => {
    try {
      return this.workingHour(data, {
        // msg: `Une correction a été apportée à vos heures travaillées du ${toFrenchLongFullDate(
        //   data.startDate
        // )}`,
        msg: "your_working_hours_updated",
        startDate: data.startDate,
        endDate: data.endDate,
        path:
          PATH_WORKING_HOUR_UPDATE +
          (data?.startDate ? "?date=" + data.startDate : ""),
        tab: TAB_HOURS_WORKED,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  // Horaire
  schedule = (data, subData = {}) => {
    try {
      const dataNotification = {
        icon: "HoursCorrection.svg",
        trigger: {
          name: NOTIFICATION_HORAIRE,
          data: data._id,
        },
        ...subData,
      };

      if (!Array.isArray(data)) {
        return this.notifyEmployer({
          data,
          dataNotification,
        });
        // If array data, notify multiple users
      } else {
        let usersToNotify = data.map((horary) => {
          const ownerId = getEmployerId(horary);
          return ownerId !== localStorage.getItem("userId")
            ? ownerId
            : undefined;
        });
        usersToNotify = uniqBy(usersToNotify);
        let notifications = [];
        usersToNotify.forEach((ownerId) => {
          if (Boolean(ownerId)) {
            notifications.push({
              ...dataNotification,
              type: NOTIFICATION_TYPE_USER,
              ownerId,
              trigger: {
                data: data[0]?._id,
                name: NOTIFICATION_HORAIRE,
              },
            });
          }
        });
        if (notifications.length === 0) return;
        return NotificationService.createNotification(notifications);
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  onboardingEmployeTask = (data = {}) => {
    try {
      const dataNotification = {
        icon: "taskTodo.svg",
        msg: "assignement_onboarding_task",
        path: "/admin/assigned-task",
        trigger: {
          name: NOTIFICATION_ONBOARDING_EMPLOYE_TASK,
          data: data._id,
        },
        taskName: data.name,
        taskDeadline: data.deadlineDate,
        type: NOTIFICATION_TYPE_USER,
      };

      if (Array.isArray(data.assignedTo)) {
        let notifications = [];
        data.assignedTo.forEach((ownerId) => {
          if (Boolean(ownerId)) {
            notifications.push({
              ...dataNotification,
              ownerId,
            });
          }
        });
        if (notifications.length === 0) return;
        return NotificationService.createNotification(notifications);
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  taskCanceled = (task, connectedUserInfo) => {
    try {
      const dataNotification = {
        icon: "taskCanceled.svg",
        msg: "task_canceled",
        path: "/admin/assigned-task",
        trigger: {
          name: NOTIFICATION_ONBOARDING_EMPLOYE_TASK,
          data: task._id,
        },
        taskName: task.name,
        taskDeadline: task.deadlineDate,
        type: NOTIFICATION_TYPE_USER,
        legalFirstName: connectedUserInfo.legalFirstName,
        legalName: connectedUserInfo.legalName,
        userId:
        connectedUserInfo?.Employer?.[0]?._id ||
        connectedUserInfo?.Employer?._id ||
        connectedUserInfo?.Employer ||
        null,
      };

      if (Array.isArray(task.assignedTo)) {
        let notifications = [];
        for (let ownerId of task.assignedTo) {
          if (ownerId) {
            notifications.push({
              ...dataNotification,
              ownerId,
            });
          }
        }

        if (notifications.length > 0) {
          return NotificationService.createNotification(notifications);
        }
      }

      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  taskPerformed = (task, connectedUserInfo) => {
    try {
      const dataNotification = {
        icon: "taskPerformed.svg",
        msg: "task_performed",
        path: "/admin/assigned-task",
        trigger: {
          name: NOTIFICATION_ONBOARDING_EMPLOYE_TASK,
          data: task._id,
        },
        taskName: task.name,
        taskDeadline: task.deadlineDate,
        type: NOTIFICATION_TYPE_USER,
        legalFirstName: connectedUserInfo.legalFirstName,
        legalName: connectedUserInfo.legalName,
        userId:
        connectedUserInfo?.Employer?.[0]?._id ||
        connectedUserInfo?.Employer?._id ||
        connectedUserInfo?.Employer ||
        null,
      };

      if (Array.isArray(task.assignedTo)) {
        let notifications = [];
        for (let ownerId of task.assignedTo) {
          if (ownerId) {
            notifications.push({
              ...dataNotification,
              ownerId,
            });
          }
        }

        if (notifications.length > 0) {
          return NotificationService.createNotification(notifications);
        }
      }

      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  scheduleMultipleUsers = (data, subData = {}) => {
    try {
      const dataNotification = {
        icon: "HoursCorrection.svg",
        trigger: {
          name: NOTIFICATION_HORAIRE,
          data: data._id,
        },
        ...subData,
      };
      return this.notifyEmployer({
        data,
        dataNotification,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  scheduleUpdate = (data) => {
    data.map((hour) => {
      return this.getHourWithTimezone(hour);
    });
    try {
      const startDate = data?.[0]?.start;
      const endDate = data?.[data.length - 1]?.end;
      return this.schedule(data, {
        // msg: `Votre horaire du ${scheduleDateMsg(data)} a été modifié`,
        msg: "your_timetable_updated",
        path: PATH_SCHEDULE.concat(
          "?date=",
          moment(startDate).format("YYYY-MM-DD"),
          "&endDate=",
          moment(endDate).format("YYYY-MM-DD"),
        ),
        startDate: startDate,
        endDate: endDate,
        tab: TAB_HOURS_WORKED,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  scheduleDelete = (data) => {
    data.map((hour) => {
      return this.getHourWithTimezone(hour);
    });
    try {
      return this.schedule(data, {
        // msg: `Votre horaire du ${scheduleDateMsg(data)} a été supprimé`,
        msg: "your_timetable_deleted",
        startDate: data?.[0]?.start,
        endDate: data?.[data.length - 1]?.end,
        path:
          PATH_SCHEDULE +
          (data?.[0]?.start
            ? "?date=" + moment(data[0].start).format("YYYY-MM-DD")
            : ""),
        tab: TAB_HOURS_WORKED,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  getHourWithTimezone = (hour) => {
    hour.end = moment(hour.end).tz(hour.timezone).format("YYYY-MM-DD HH:mm");
    hour.end = moment.tz(hour.end, moment.tz.guess()).format();
    hour.start = moment(hour.start)
      .tz(hour.timezone)
      .format("YYYY-MM-DD HH:mm");
    hour.start = moment.tz(hour.start, moment.tz.guess()).format();
    return hour;
  };

  scheduleAssignment = (data) => {
    data.map((hour) => {
      return this.getHourWithTimezone(hour);
    });
    try {
      const startDate = data?.[0]?.start;
      const endDate = data?.[data.length - 1]?.end;
      return this.schedule(data, {
        msg: "new_timetable_assigned",
        startDate: startDate,
        endDate: endDate,
        path: PATH_SCHEDULE.concat(
          "?date=",
          moment(startDate).format("YYYY-MM-DD"),
          "&endDate=",
          moment(endDate).format("YYYY-MM-DD"),
        ),
        tab: TAB_HOURS_WORKED,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  fileManager = (data, subData, userNotification = true) => {
    try {
      let dataNotification = {
        icon: "fileManager.svg",
        trigger: {
          name: NOTIFICATION_DOCUMENT_STORAGE,
          data: data?._id,
        },
        ...subData,
      };
      if (!userNotification) {
        dataNotification = {
          ...dataNotification,
          userId: data?.employerId || null,
        };
      }

      if (userNotification) {
        return this.notifyEmployer({
          data,
          dataNotification,
        });
      } else {
        return this.notifyEnterprise({
          dataNotification,
          filters: { employerId: data?.employerId },
        });
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  fileAddedIntoFolder = (data) => {
    try {
      if (!data.isVisible) return false;
      const folderName = getParentFolderName(data.key);
      if (!folderName) return new Error("No parent folder");
      data["Employer"] = { _id: data.employerId };
      return this.fileManager(data, {
        // msg: `Un fichier a été ajouté à votre dossier ${folderName}`,
        msg: "file_added",
        folderName: folderName,
        type: NOTIFICATION_TYPE_USER,
        path: PATH_MA_FICHE,
        tab: TAB_FICHE_EMPLOYE_DOCUMENT,
      });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  // + Employer full data attribute
  fileAddedIntoFolderForEnterprise = async (data) => {
    try {
      const folderName = getParentFolderName(data.key);
      return this.fileManager(
        data,
        {
          // msg: `${legalFirstName} ${legalName} a ajouté un fichier à son dossier ${folderName}`,
          msg: "new_file",
          folderName: folderName,
          type: NOTIFICATION_TYPE_ENTERPRISE,
          path: PATH_DETAIL_EMPLOYER.concat(
            data.idEmployeSalary,
            "/",
            data.employerId,
            "/?tabIndex=DListDocumentNote",
          ),
          tab: TAB_LIST_DOCUMENT_NOTE,
          legalFirstName: data.legalFirstName,
          legalName: data.legalName,
          userId:
          data?.Employer?.[0]?._id ||
          data?.Employer?._id ||
          data?.Employer ||
          null,
        },
        false,
      );
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  emploieCertificationUpdate = (data) => {
    try {
      const dataNotification = {
        icon: "fileManager.svg",
        trigger: {
          name: NOTIFICATION_EMPLOIE_CERTIFICATION,
          data: data._id,
        },
        // msg: "Une modification a été faite dans vos certifications",
        msg: "certifications_updated",
        type: NOTIFICATION_TYPE_USER,
        path: PATH_MA_FICHE,
        tab: TAB_FICHE_CERT_FORMATION,
      };
      return this.notifyEmployer({ data, dataNotification });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  emploieCertificationAdd = (data) => {
    try {
      const dataNotification = {
        icon: "fileManager.svg",
        trigger: {
          name: NOTIFICATION_EMPLOIE_CERTIFICATION,
          data: data._id,
        },
        // msg: "Certification ajoutée",
        msg: "certifications_added",
        type: NOTIFICATION_TYPE_USER,
        path: PATH_MA_FICHE,
        tab: TAB_FICHE_CERT_FORMATION,
      };
      return this.notifyEmployer({ data, dataNotification });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  emploiFormationUpdate = (data) => {
    try {
      const dataNotification = {
        icon: "fileManager.svg",
        trigger: {
          name: NOTIFICATION_EMPLOIE_FORMATION,
          data: data._id,
        },
        // msg: "Formation modifiée",
        msg: "formations_updated",
        type: NOTIFICATION_TYPE_USER,
        path: PATH_MA_FICHE,
        tab: TAB_FICHE_CERT_FORMATION,
      };
      return this.notifyEmployer({ data, dataNotification });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  emploiFormationAdd = (data) => {
    try {
      const dataNotification = {
        icon: "fileManager.svg",
        trigger: {
          name: NOTIFICATION_EMPLOIE_FORMATION,
          data: data._id,
        },
        // msg: "Formation ajoutée",
        msg: "formations_added",
        type: NOTIFICATION_TYPE_USER,
        path: PATH_MA_FICHE,
        tab: TAB_FICHE_CERT_FORMATION,
      };
      return this.notifyEmployer({ data, dataNotification });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  emploieCourseUpdate = (data) => {
    try {
      const dataNotification = {
        icon: "fileManager.svg",
        trigger: {
          name: NOTIFICATION_EMPLOIE_COURSE,
          data: data._id,
        },
        // msg: "Une modification a été faite dans votre parcours professionnel",
        msg: "professionnal_career_updated",
        type: NOTIFICATION_TYPE_USER,
        path: PATH_MA_FICHE,
        tab: TAB_JOB_SALARY_HOLIDAYS,
      };
      return this.notifyEmployer({ data, dataNotification });
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  notifyEmployer = (params) =>
    new Promise((resolve, reject) => {
      const { data, dataNotification } = params;
      const employerId = getEmployerId(data);
      dataNotification.type = NOTIFICATION_TYPE_USER;
      if (!employerId) {
        console.error(
          "Employer info not found: Add an info employer on the attribute of the param data of notification",
        );
        resolve(false);
      }
      const paramService = {
        employerId,
        dataNotification,
        currentUserId: localStorage.getItem("userId"),
      };
      NotificationService.notificationEmployer(paramService)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          resolve(err);
        });
    });

  notifyAllUsers = (params) =>
    new Promise((resolve) => {
      const { dataNotification, filters } = params;
      dataNotification.type = NOTIFICATION_TYPE_USER;
      const paramService = {
        enterpriseId: localStorage.getItem("enterpriseId"),
        currentUserId: localStorage.getItem("userId"),
        dataNotification,
        filters,
      };
      NotificationService.notificationEnterprise(paramService)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });

  notifyByAccess = (params) =>
    new Promise((resolve, reject) => {
      const { dataNotification, filters } = params;
      dataNotification.type = NOTIFICATION_TYPE_USER;
      const paramService = {
        enterpriseId: localStorage.getItem("enterpriseId"),
        currentUserId: localStorage.getItem("userId"),
        dataNotification,
        filters,
      };
      NotificationService.notificationByAccess(paramService)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });

  notifyEnterprise = (params) =>
    new Promise((resolve) => {
      const { dataNotification, filters } = params;
      dataNotification.type = NOTIFICATION_TYPE_ENTERPRISE;
      const paramService = {
        enterpriseId: localStorage.getItem("enterpriseId"),
        dataNotification,
        currentUserId: localStorage.getItem("userId"),
        filters,
      };
      NotificationService.notificationEnterprise(paramService)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });

  showNativeNotification = (notification, t, language) => {
    const { dataEmployer, dataEnterprise, content, key } = getContentAndKey(
      notification,
      t,
      language,
    );

    const icon =
      dataEmployer?.profileImage ||
      dataEnterprise?.logo ||
      BLANK_PROFILE_PICTURE;

    addNotification({
      title:
        dataEmployer?.fullName ||
        dataEnterprise?.enterpriseName ||
        "Notification!",
      message: `${key} ${content}`,
      duration: 10000,
      native: true,
      silent: false,
      icon,
    });

    const audioCfg = new Audio(tickAudio);
    audioCfg.volume = 1.0;
    audioCfg.play();
  };
  push = (notification, t, language, subscription) => {
    const { dataEmployer, dataEnterprise, content, key, path } =
      getContentAndKey(notification, t, language);
    subscription.endPoint = process.env.REACT_APP_URL + path;
    const icon =
      dataEmployer?.profileImage || dataEnterprise?.logo || LOGO_ALTEE;
    NotificationPushService.push({
      title:
        dataEmployer?.fullName || dataEnterprise?.name || "ARH Notification!",
      message: `${key} ${content}`,
      icon,
      path,
      subscription,
    });
  };
}

export const NotificationUtil = new NotificationUtilClass();

import BaseService from "../base.service";
import http from "../../http-common";
class Category extends BaseService {
  constructor() {
    super("category");
  }
  getAllCategories(id) {
    return http.get(`${this.path}/${id}`);
  }
  insert(data) {
    return http.post(`${this.path}/`, data);
  }
  updateById(data) {
    return http.put(`${this.path}/${data._id}`, data);
  }
  deleteById(_id) {
    return http.delete(`${this.path}/${_id}`);
  }

 
}

export default new Category();
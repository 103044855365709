import { useEffect, useRef } from "react";

function useOutsideCallBack(callBack) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        typeof callBack === "function"
      ) {
        callBack();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callBack]);

  return { wrapperRef };
}

export { useOutsideCallBack };
export default useOutsideCallBack;

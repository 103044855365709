import http from "../../http-common";
import BaseService from "../base.service";

class Reimbursement extends BaseService {
  constructor() {
    super("reimbursement");
  }

  insert(data, params = {}) {
    return http.post(`${this.path}/`, data, { params });
  }
  getByEmployer(id, params = {}) {
    return http.get(`${this.path}/${id}`, { params });
  }
  getByEnterprise(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/lists/${id}`, { params }); 
  }
  getExportByEnterprise(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    };
    return http.get(`${this.path}/export/${id}`, { params });
  }
  deleteById(id, params) {
    return http.delete(`${this.path}/${id}`, { params });
  }
  updateById(data, params) {
    return http.put(`${this.path}/${data._id}`, data, { params });
  }
  updateMany(data, params) {
    return http.put(`${this.path}/update-many/${data.status}`, data, {
      params
    });
  }
  getRecentsItineraries(employerId) {
    return http.get(`${this.path}/itineraries/${employerId}`);
  }
  getDistancesTotalOfTheYear(id, params = {}) {
    return http.get(`${this.path}/distances/${id}`, { params });
  }
}

export default new Reimbursement();

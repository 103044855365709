import { getResponseQuery as _q } from "../../helpers/_functions";
import http from "../../http-common";
import BaseService from "../base.service";

// const _q = getResponseQuery;

class OnboardingProtocol extends BaseService {
  constructor() {
    super("onboarding-protocol");
  }

  createProtocol = (data) => _q(super.insert(data));

  updateProtocol = (protocol) => _q(super.updateById(protocol));

  updateOrderProtocol = (protocols) => _q(super.updateOrder(protocols));

  deleteProtocol = (protocolId) => _q(super.deleteById(protocolId));

  duplicateProtocol = (protocolId) => _q(http.post(`${this.path}/duplicate/${protocolId}`));

  getProtocolsWithTasks = (enterpriseId) =>
    _q(http.get(`${this.path}/get-protocols-with-tasks/${enterpriseId}`));
}

export default new OnboardingProtocol();

import { combineReducers } from "redux";

import abstract from "./_abstractReducer";
import calendar from "./_calendarReducer";
import commonData from "./_commonDataReducer";
import connectedEmployeDataReducer from "./_connectedEmployeDataReducer";
import developmentPlanReducer from "./_developmentPlanReducer";
import documentationReducer from "./_documentationReducer";
import enterpriseEmployeDataReducer from "./_enterpriseEmployeDataReducer";
import evaluationReducer from "./_evaluationReducer";
import formationReducer from "./_formationReducer";
import materials from "./_materialsReducer";
import newsReducer from "./_newsReducer";
import notification from "./_notificationReducer";
import onboardingReducer from "./_onboardingReducer";
import recruitmentsReducer from "./_recruitmentsReducer";
import reimbursmentsReducer from "./_reimbursmentsReducer";
import reportReducer from "./_reportReducer";
import settingsReducer from "./_settingsReducer";
import userConnected from "./_userConnectedReducer";
import workingHoursReducer from "./_workingHoursReducer";
import certificationReducer from "./_certificationReducer";

const createReducer =
  ({ initialState = {}, handlers = {} }) =>
  (state = initialState, { type, payload } = {}) =>
    type in handlers ? handlers[type](state, payload) : state;

export default combineReducers({
  abstract: createReducer(abstract),
  calendar: createReducer(calendar),
  commonData: createReducer(commonData),
  materials: createReducer(materials),
  notification: createReducer(notification),
  userConnected: createReducer(userConnected),
  settings: createReducer(settingsReducer),
  recruitments: createReducer(recruitmentsReducer),
  news: createReducer(newsReducer),
  onboarding: createReducer(onboardingReducer),
  reports: createReducer(reportReducer),
  reimbursments: createReducer(reimbursmentsReducer),
  connectedEmployeData: createReducer(connectedEmployeDataReducer),
  enterpriseEmployeData: createReducer(enterpriseEmployeDataReducer),
  workingHours: createReducer(workingHoursReducer),
  evaluation: createReducer(evaluationReducer),
  documentation: createReducer(documentationReducer),
  formation: createReducer(formationReducer),
  developmentPlan: createReducer(developmentPlanReducer),
  certification: createReducer(certificationReducer),
});

import BaseService from "../base.service";
import http from "../../http-common";
class Itinerary extends BaseService {
  constructor() {
    super("itinerary");
  }
  getAllItineraries(id,userId) {
    return http.get(`${this.path}/${id}/${userId}`);
  }
  insert(data) {
    return http.post(`${this.path}/`, data);
  }
  updateById(data) {
    return http.put(`${this.path}/${data._id}`, data);
  }
  deleteById(_id) {
    return http.delete(`${this.path}/${_id}`);
  }

 
}

export default new Itinerary();
import BaseService from "../base.service";
import http from "../../http-common";
import { getResponseQuery } from "../../helpers/_functions";

class EvaluationQuestion extends BaseService {
  constructor() {
    super("evaluation-question");
  }

  createEvaluationQuestion = (data) => getResponseQuery(super.insert(data));

  updateEvaluationquestion = (evaluationQuestion) => 
    getResponseQuery(super.updateById(evaluationQuestion));
  
  deleteEvaluationQuestion = (evaluationQuestionId) => 
    getResponseQuery(super.deleteById(evaluationQuestionId));

  getQuestionEvaluation = (enterpriseId) =>
    getResponseQuery(http.get(`${this.path}/get-question/${enterpriseId}`));
}

export default new EvaluationQuestion();
import BaseService from "./base.service";
import http from "../http-common";
import { getResponseData } from "../helpers/_functions";

class UserSettingService extends BaseService {
    constructor() {
        super("user-setting")
    }

    insert(data) {
        return new Promise((resolve, reject) => {
                super
                .insert(data)
                .then(res => resolve(getResponseData(res)))
                .catch(err => reject(err));
        });
    }

    getByUser({ userId, type }) {
        return new Promise((resolve, reject) => {
            http
            .get(`${this.path}/getByUser/${userId}/${type}`)
            .then(res => resolve(getResponseData(res)))
            .catch(err => reject(err));
        });
    }
}

export default new UserSettingService();
import BaseService from "./base.service";
import http from "../http-common";
class DayOffService extends BaseService {
    constructor() {
        super("day-off");
    }
    createDayOff(data) {
        return http.post(`${this.path}/create-day-off-enterprise`, data);
    }
    updateDayOffById(item) {
        return http.put(`${this.path}/update-day-off-enterprise/${item._id}`, item);
    }
    deleteDayOffById(id) {
        return http.delete(`${this.path}/delete-day-off-enterprise/${id}`);
    }
    getDayOffById(id) {
        return http.get(`${this.path}/list-day-off-enterprise/${id}`);
    }
    getDayOffByCountry(country) {
        return http.get(`${this.path}/listByCountry-day-off-enterprise/${country}`);
    }
    getDayOffByEnterprise(id, params = {}) {
        return http.get(`${this.path}/listByEnterprise/${id}`, { params });
    }
    getFilteredDayOffs(country, year) {
        return http.get(`${this.path}/filter/${country}/${year}`);
    }
    getYearByCountry(country) {
        return http.get(`${this.path}/listAllYearByCountry/${country}`);
    }
    getAllCountry() {
        return http.get(`${this.path}/listAllCountry`);
    }
}

export default new DayOffService();

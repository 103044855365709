import { applyMiddleware, compose } from "redux";
import { embeddedMiddleware } from "./_embeddedMiddleware";
import { rulesMiddleware } from "./_rulesMiddleware";
import { thunkMiddleware } from "./_thunkMiddleware";

// activate redux devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true
    })
  : compose;

export default composeEnhancers(
  applyMiddleware(
    rulesMiddleware,
    embeddedMiddleware,
    thunkMiddleware
  )
);
import { makeActionHandler } from "./_reducerUtils";

export const onboardingData = {
  initialState: {
    employeFlow: {
      list: [],
      come: 0,
      departure: 0,
      filter: {},
      isLoaded: false,
      isFiltered: false,
    },
    protocol: {
      list: [],
      isLoaded: false,
    },
    employeProtocol: {
      selected: null,
      isLoading: false,
      metadata: {
        employeeId: null,
        flowType: null,
        unavailability: null,
        employeProtocolId: null,
      },
    },
    employeTask: {
      list: [],
      filter: {},
      isLoaded: false,
    },
    indisponibility: {
      list: [],
      filter: {},
      isLoaded: false,
      isFiltered: false,
    },
    typeDemission: {
      list: [],
      isLoaded: false,
    },
  },
  handlers: {
    "onboarding/SET_EMPLOYER_FLOW": makeActionHandler("employeFlow"),
    "onboarding/SET_PROTOCOL": makeActionHandler("protocol"),
    "onboarding/SET_EMPLOYE_PROTOCOL": makeActionHandler("employeProtocol"),
    "onboarding/SET_EMPLOYE_TASK": makeActionHandler("employeTask"),
    "onboarding/SET_INDISPONIBILITY": makeActionHandler("indisponibility"),
    "onboarding/SET_TYPEDEMISSION": makeActionHandler("typeDemission"),
    RESET_ALL: () => onboardingData.initialState,
  },
};

export default onboardingData;

import React, { Fragment, useState, useCallback, useEffect } from "react";

import { Listbox, Transition } from "@headlessui/react";
import { Box } from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Rating } from "@material-ui/lab";
import { Caret } from "@routes/IconeSvg";

//SvgResetFilter

const arhTheme = createTheme({
  palette: {
    secondary: {
      main: "#73C7B7",
    },
    primary: {
      main: "#73C7B7",
    },
  },
  overrides: {
    MuiBox: {
      root: {
        marginBottom: "0",
      },
    },
  },
});

/** @type typeof Rating */
// @ts-ignore
const StyledRating = withStyles({
  iconFilled: {
    color: "#81dac9",
  },
  iconHover: {
    color: "#73C7B7",
  },
})(Rating);

/**
 * @typedef {Object} Props
 * @property {string} name
 * @property {boolean=} invalid
 * @property {boolean=} readOnly
 * @property {string=} customListHeight
 * @property {Array<any>} employeeList
 * @property {string=} anotherParentClass
 * @property {boolean=} topView
 * @property {(value: any) => void} setValue
 * @property {string=} classParentList
 * @property {string=} classLabel
 * @property {string=} label
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export default function ListboxRating(props) {
  const {
    name,
    invalid = false,
    readOnly = false,
    customListHeight = "arh-max-h-60",
    employeeList,
    anotherParentClass,
    topView,
    setValue,
    value,
  } = props;

  const [ratingValue, setRatingValue] = useState(0);
  // function resetValue(e) {
  //     e.stopPropagation();
  //     setSelectedValue(multiple ? [] : "");
  // }

  useEffect(() => setRatingValue(value), [value]);

  const onSelectedChange = useCallback((e, value) => {
    setRatingValue(value);
    setValue(value);
  }, []);

  return (
    <Listbox
      value={ratingValue}
      name={name}
      disabled={readOnly}
      onChange={() => {}}
    >
      {({ open }) => (
        <div className={props.classParentList + " arh-relative arh-w-full"}>
          {props.label ? (
            <label
              className={`
                            arh-mb-1 arh-flex arh-w-full arh-font-poppins arh-text-xs arh-font-medium 
                            ${props?.classLabel} 
                            ${
                              invalid
                                ? " arh-text-[#F60A20] "
                                : " arh-text-black "
                            } 
                        `}
            >
              {props?.label}
            </label>
          ) : (
            ""
          )}
          <Listbox.Button
            className={`arh- arh-relative arh-h-11 arh-w-full arh-cursor-pointer arh-rounded arh-border arh-border-solid arh-py-3 arh-pl-6 arh-pr-8 arh-text-left arh-font-poppins arh-text-xs arh-font-normal focus:arh-outline-none focus-visible:arh-border-[#ECECEC] focus-visible:arh-ring-2 focus-visible:arh-ring-[#ECECEC] focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor ${
              !invalid
                ? " arh-border-[#ECECEC] arh-bg-white placeholder:arh-text-[#8B8F91] "
                : " arh-border-[#F60A20] arh-bg-[#FFF3F4] arh-text-[#F60A20] placeholder:arh-text-[#F60A20]"
            } ${readOnly && "arh-bg-[#e9ecef]"}`}
            as="div"
          >
            {/* {ratingValue &&
                            
                                <span
                                    className="arh-cursor-pointer arh-absolute arh-inset-y-0 arh-left-0 arh-flex arh-items-center arh-pl-[10px]"
                                    onClick={resetValue}
                                >
                                    <SvgResetFilter />
                                </span>
                            */}

            <ThemeProvider theme={arhTheme}>
              <Box component="fieldset" mb={3} borderColor="transparent">
                <StyledRating
                  name="ratingKeyDisabled"
                  value={ratingValue}
                  precision={1}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  onChangeActive={() => {}}
                  className="arh-pointer-events-none"
                />
              </Box>
            </ThemeProvider>
            <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
              <Caret
                className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="arh-transition arh-ease-in arh-duration-100"
            leaveFrom="arh-opacity-100"
            leaveTo="arh-opacity-0"
          >
            <Listbox.Options
              className={`${
                employeeList ? "arh-max-h-[15.7rem]" : customListHeight
              }
                            arh-absolute   arh-mt-1 arh-w-full  arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-px-4 arh-py-6 arh-text-xs arh-shadow-xs 3xl:arh-w-[120%] desktop-l:arh-w-[100%]
                            ${anotherParentClass} ${
                              customListHeight !== "arh-max-h-60" &&
                              "arh-overflow-y-auto"
                            } ${open && 'arh-z-[6]'}
                            ${topView && "arh-bottom-full arh-mb-1"}
                            `}
            >
              <Listbox.Option
                key="ratingKey"
                className={`arh-relative arh-flex arh-cursor-pointer arh-select-none arh-list-none  arh-truncate arh-font-poppins arh-text-xs arh-font-normal arh-text-darkColor ${
                  employeeList ? "arh-mb-1" : "arh-py-1.5"
                }`}
                value={ratingValue}
              >
                <ThemeProvider theme={arhTheme}>
                  <Box component="fieldset" mb={3} borderColor="transparent">
                    <StyledRating
                      name="ratingKey"
                      defaultValue={ratingValue}
                      precision={1}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                      onChange={onSelectedChange}
                    />
                  </Box>
                </ThemeProvider>
              </Listbox.Option>
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

import BaseService from "./base.service";
import http from "../http-common";
import { checkEnterpriseId, getResponseData } from "../helpers/_functions";

class EmployerHoursService extends BaseService {
  constructor() {
    super("employer-hours");
  }
  getByEmployer(id) {
    return http.get(`${this.path}/getByEmployer/${id}`);
  }

  getByEmployerAndDate(id, params = {}) {
    return http.get(`${this.path}/listByDate/${id}`, { params });
  }

  getByEnterpriseWithOutFilter(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http
      .get(`${this.path}/listWithOutFilter/${id}`, { params })
      .then((response) => getResponseData(response));
  }

  getOneWeekEmployerHoursByEmployer(params) {
    const { date, employerId } = params;
    return http.get(
      `${this.path}/getOneWeekEmployerHoursByEmployer/${employerId}/${date}`
    );
  }
  getOneMonthByEmployer(params) {
    const { date, employerId } = params;
    return http.get(`${this.path}/getOneMonthByEmployer/${employerId}/${date}`);
  }
  getByEmployerBetweenTwoDateForCalendar(params) {
    const { startDateParam, endDateParam, employerId } = params;
    return http.get(
      `${this.path}/getByEmployerBetweenTwoDateForCalendar/${employerId}/${startDateParam}/${endDateParam}`
    );
  }
  updateByEmployer(employerHours) {
    return http.post(
      `${this.path}/deleteByEmployer/${employerHours.Employer}`,
      employerHours
    );
  }
  deleteByEmployer(employerId) {
    return http.delete(`${this.path}/deleteByEmployer/${employerId}`);
  }
  deleteByEntreprise(enterpriseId) {
    return http.delete(`${this.path}/deleteByEntreprise/${enterpriseId}`);
  }
  getWorkingHoursByEnterprise(enterpriseId, dataAfter) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    return http
      .get(`${this.path}/list/${enterpriseId}/${dataAfter}`)
      .then((response) => getResponseData(response));
  }
  getEmployerHoursOneWeekForCalendar(params) {
    const { employerId, date } = params;
    return http.get(
      `${this.path}/getEmployerHoursOneWeekForCalendar/${employerId}/${date}`
    );
  }

  getEmployerHoursWithStatusByEnterprise(enterpriseId, params = {}) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http
      .get(`${this.path}/listEmployersHoursWithStatus/${enterpriseId}`, {
        params,
      })
      .then((response) => getResponseData(response));
  }

  // deprecated function
  getPointingCorrectionByEnterprise(enterpriseId, params = {}) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    return http
      .get(`${this.path}/getPointingCorrection/${enterpriseId}`, { params })
      .then((response) => getResponseData(response));
  }
  
}

export default new EmployerHoursService();

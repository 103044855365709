import BaseService from "./base.service";
import http from "../http-common";

class DataFileService extends BaseService {
  constructor() {
    super("data-file");
  }

  deleteFile(id) {
    return http.get(`${this.path}/deleteFile/${id}`);
  }
  insertWithDocument(data){
    return http.post(`${this.path}/create-with-document`, data);
  }
  getDocumentsByEmployer(EmployerId) {
    return http.get(`${this.path}/getDocumentsByEmployer/${EmployerId}`);
  }
  getDocumentsByEntreprise(entrepriseId) {
    return http.get(`${this.path}/getDocumentsByEntreprise/${entrepriseId}`);
  }
  findBySource({ sourceModel, source }) {
    return http.get(`${this.path}/findBySource/${sourceModel}/${source}`);
  }
  createFolder(data) {
    return http.post(`${this.path}/createFolder`, data);
  }
  updateFolder(data) {
    return http.post(`${this.path}/updateFolder`, data);
  }
  update(id, data) {
    return http.put(`${this.path}/update/${id}`, data);
  }
}

export default new DataFileService();

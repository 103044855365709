import axios from "axios";
import baseUrl from "./routes/url";

const _axios = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/json"
  }
});

_axios.interceptors.request.use(config => {
  config.headers.common['x-access-token'] = localStorage.getItem('token') || "no token set here";
  return config;
});

export default _axios;
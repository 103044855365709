import React, { useState } from "react";
import ReactDOM from "react-dom";
import Routes from "@routes/router";
import "@assets/css/generales/default.css";
import '@assets/tailwindcss/tailwind.css';
import "@assets/css/generales/fonts.css";
import "@assets/main.css";
import "@responsivity/responsivityMobile/defaultMobile.css";
import "@responsivity/breakpointsDropdown.css";
import "@helpers/ArhCustomPrototypes.js";
import "@css/pages/erreurPage/pageErreur.css";
import { Provider } from "react-redux";
import store from "@store/index";
import "./i18n";
import ErrorFallback from "@pages/errorPage/ErrorFallback";
// import ReactNotification from "react-notifications-component";
// import "react-notifications-component/dist/theme.css";
import { ErrorBoundary } from 'react-error-boundary'

window.store = store;
const mount = document.querySelectorAll("div.demo-mount-nested-editable");

function App() {
  const [errorInfo, setErrorInfo] = useState("");
  const [isErrorSent, setIsErrorSent] = useState(false);
  const handleError = (error, { componentStack }) => {
    setErrorInfo(componentStack)
  }

  const errorAdditionalProps = {
    isErrorSent,
    setIsErrorSent
  }

  return (
    <Provider store={store}>
        {/* <ReactNotification /> */}
        <ErrorBoundary FallbackComponent={(prop) => <ErrorFallback {...prop} {...errorAdditionalProps} info={errorInfo} />} onError={handleError}>
          <Routes />
        </ErrorBoundary>
    </Provider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById("root"),
  mount[0]
);


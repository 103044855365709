import React from "react";

const FloatButton = ({ id=null, size="",spaceButton="", color="", onClick=()=>{}, children }) => {
  return (
    <button
      id={id}
      className={
        size +
        " " +
        spaceButton + " " +
        " arh-shrink-0 arh-flex arh-justify-center arh-items-center arh-rounded-full arh-border arh-border-white arh-border-solid arh-transition-all arh-duration-200 arh-ease-in arh-cursor-pointer " +
        color
      }
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default FloatButton;

import BaseService from "./base.service";
import http from "../http-common";
import { checkEnterpriseId, getResponseData } from "../helpers/_functions";
class NewsService extends BaseService {
  constructor() {
    super("news");
  }
  getById(id) {
    return http.get(`${this.path}/get/${id}`);
  }
  getByUserId(id) {
    return http.get(`${this.path}/getByUser/list/${id}`);
  }
  // getByEnterprise(id, dataAfter, actualise) {
  //   return http.get(`${this.path}/getNewsByEntrepriseId/list/${id}/${dataAfter}/${actualise}`);
  // }
  updateLike(item) {
    return http.put(`${this.path}/updateLike/${item._id}`, item);
  }
  createLike(data) {
    return http.post(`${this.path}/createLike`, data);
  }
  createTag(data) {
    return http.post(`${this.path}/create-tag`, data);
  }
  createComment(data) {
    return http.post(`${this.path}/createComment`, data);
  }
  createVisibility(data) {
    return http.post(`${this.path}/create-visibility`, data);
  }
  updateVisibility(item) {
    return http.put(`${this.path}/update-visibility/${item._id}`, item);
  }
  newsVue(item) {
    return http.put(`${this.path}/news-vue/${item._id}`, item);
  }
  createVue(data) {
    return http.put(`${this.path}/createVue`, data);
  }
  deleteNewsVue(newsVueIds) {
    return http.post(`${this.path}/deleteNewsVue`, { newsVueIds });
  }
  deleteNewsVueByEmployer(id) {
    return http.delete(`${this.path}/deleteNewsVueByEmployer/${id}`);
  }
  deleteNewsVisibility(id) {
    return http.delete(`${this.path}/deleteNewsVisibility/${id}`);
  }
  deleteNewsById(id, enterpriseId) {
    return http.delete(`${this.path}/delete/${id}/${enterpriseId}`);
  }
  getByEmployer(id, params = {}) {
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http.get(`${this.path}/getByEmployer/list/${id}`, { params });
  }
  getByEnterprise(enterpriseId, params = {}) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    params = {
      ...params,
      userId: localStorage.getItem("userId")
    }
    return http
      .get(`${this.path}/getNewsByEntrepriseId/list/${enterpriseId}`, {
        params,
      })
      .then((response) => getResponseData(response));
  }
  getNewsTagByEntrepriseId(enterpriseId) {
    enterpriseId = checkEnterpriseId(enterpriseId);
    return http
      .get(`${this.path}/getNewsTagByEntrepriseId/list/${enterpriseId}`)
      .then((response) => getResponseData(response));
  }
  deleteNewsTagById(id, enterpriseId) {
    return http.delete(`${this.path}/deleteNewsTag/${id}/${enterpriseId}`);
  }
  updateNewsTag(data) {
    return http.post(`${this.path}/update-tag`, data);
  }
  updateOneNews(data) {
    return http.post(`${this.path}/updateNews`, data, {});
  }
  createOneNews(data) {
    return http.post(`${this.path}/createNews`, data, {});
  }
}

export default new NewsService();

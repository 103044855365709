import BaseService from "./base.service";
import http from "../http-common";
class EmploiNoteService extends BaseService {
  constructor() {
    super("emploi-note");
  }
  getByEmployer(id, params = {}) {
    params = {
      ...params,
      userQuerySource: localStorage.getItem("userId")
    }
    return http.get(`${this.path}/getByEmployer/${id}`, { params });
  }

  getById(id) {
    return http.get(`${this.path}/getById/${id}`);
  }
}

export default new EmploiNoteService();

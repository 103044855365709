// Define a properties of Array, principaly used on LisboxSimple Tailwind
Object.defineProperties(Array.prototype, {
    'sortByName': {
        enumerable: false,
        value: function() {
            return this.sort((a, b) => {
                if (a?.name < b?.name) return -1;
                if (a?.name > b?.name) return 1;
                return 0;
            })
        }
    },
    'mapDataForListBox': {
        enumerable: false,
        value: function(attrValue) {
            return this.map(el => ({
                _id: el?._id, 
                name: el?.[attrValue]
            }))
        }
    },
    'mapSimpleArrayForBox': {
        enumerable: false,   
        value: function() {
            return this.map((val) => ({
                _id: typeof val === 'string' ? val.toLowerCase() : val, 
                name: val
            }))
        }
    },
    'mapForListBoxAndTrans': {
        enumerable: false,
        value: function(attrValue, t, id = '_id') {
            return this.map(el => ({
                _id: el?.[id], 
                name: t(el?.[attrValue])
            }))
        }   
    },
    'mapSimpleArrayForBoxAndTransUppercase': {
        enumerable: false,
        value: function(t) {
            return this.map(val => ({
                _id: typeof val === 'string' ? val.toLowerCase() : val, 
                name: t(val)
            }))
        }
    }
})

// Custom property for string
Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
      return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});